[data-structure="bistro"] .structure_module__title,
[data-structure="bistro"] .structure_header_module__title {
  color: #ff00c8; }
  [data-structure="bistro"] .structure_module__title span:after,
  [data-structure="bistro"] .structure_header_module__title span:after {
    color: lime; }

[data-structure="bistro"] .structure_module__title:hover span:after {
  color: white; }

[data-structure="kino"] .structure_module__title,
[data-structure="kino"] .structure_header_module__title {
  color: lime; }
  [data-structure="kino"] .structure_module__title span:after,
  [data-structure="kino"] .structure_header_module__title span:after {
    color: #fffa00; }

[data-structure="kino"] .structure_module__title:hover span:after {
  color: white; }

[data-structure="knihovna"] .structure_module__title,
[data-structure="knihovna"] .structure_header_module__title {
  color: #fffa00; }
  [data-structure="knihovna"] .structure_module__title span:after,
  [data-structure="knihovna"] .structure_header_module__title span:after {
    color: lime; }

[data-structure="knihovna"] .structure_module__title:hover span:after {
  color: white; }

[data-structure="kavarna"] .structure_module__title,
[data-structure="kavarna"] .structure_header_module__title {
  color: #ff00c8; }
  [data-structure="kavarna"] .structure_module__title span:after,
  [data-structure="kavarna"] .structure_header_module__title span:after {
    color: #fffa00; }

[data-structure="kavarna"] .structure_module__title:hover span:after {
  color: white; }

[data-structure="rostliny"] .structure_module__title,
[data-structure="rostliny"] .structure_header_module__title {
  color: lime; }
  [data-structure="rostliny"] .structure_module__title span:after,
  [data-structure="rostliny"] .structure_header_module__title span:after {
    color: #fffa00; }

[data-structure="rostliny"] .structure_module__title:hover span:after {
  color: white; }

[data-structure="zahrada-pritomnosti"] .structure_module__title,
[data-structure="zahrada-pritomnosti"] .structure_header_module__title {
  color: lime; }
  [data-structure="zahrada-pritomnosti"] .structure_module__title span:after,
  [data-structure="zahrada-pritomnosti"] .structure_header_module__title span:after {
    color: #fffa00; }

[data-structure="zahrada-pritomnosti"] .structure_module__title:hover span:after {
  color: white; }

[data-structure="prodejna"] .structure_module__title,
[data-structure="prodejna"] .structure_header_module__title {
  color: #fffa00; }
  [data-structure="prodejna"] .structure_module__title span:after,
  [data-structure="prodejna"] .structure_header_module__title span:after {
    color: lime; }

[data-structure="prodejna"] .structure_module__title:hover span:after {
  color: white; }

[data-structure="stage"] .structure_module__title,
[data-structure="stage"] .structure_header_module__title {
  color: #ff00c8; }
  [data-structure="stage"] .structure_module__title span:after,
  [data-structure="stage"] .structure_header_module__title span:after {
    color: #fffa00; }

[data-structure="stage"] .structure_module__title:hover span:after {
  color: white; }

[data-structure="zahrada"] .structure_module__title,
[data-structure="zahrada"] .structure_header_module__title {
  color: lime; }

[data-structure="materialova-knihovna"] .structure_module__title,
[data-structure="materialova-knihovna"] .structure_header_module__title {
  font-size: 9vw;
  color: white; }
  [data-structure="materialova-knihovna"] .structure_module__title span:after,
  [data-structure="materialova-knihovna"] .structure_header_module__title span:after {
    color: #fffa00; }
  @media (min-width: 768px) and (max-width: 991px) {
    [data-structure="materialova-knihovna"] .structure_module__title,
    [data-structure="materialova-knihovna"] .structure_header_module__title {
      font-size: 10vw; } }
  @media all and (max-width: 767px) {
    [data-structure="materialova-knihovna"] .structure_module__title,
    [data-structure="materialova-knihovna"] .structure_header_module__title {
      font-size: 12vw; } }

[data-structure="materialova-knihovna"] .structure_module__title:hover {
  color: #fffa00; }
  [data-structure="materialova-knihovna"] .structure_module__title:hover span:after {
    color: white; }
