.event_header_module__content {
  font-family: 'Plato Basic', sans-serif;
  font-weight: normal;
  letter-spacing: 0.04em; }
  @media all and (max-width: 767px) {
    .event_header_module__content {
      font-size: 1.8rem;
      line-height: 2.52rem; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .event_header_module__content {
      font-size: 1.6rem;
      line-height: 2.24rem; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .event_header_module__content {
      font-size: 1.8rem;
      line-height: 2.52rem; } }
  @media (min-width: 1200px) {
    .event_header_module__content {
      font-size: 2rem;
      line-height: 2.8rem; } }
  @media (min-width: 1681px) {
    .event_header_module__content {
      font-size: 2rem;
      line-height: 2.8rem; } }

[data-identity="1.5"] .event_header_module__content {
  font-family: 'Plato Regular', sans-serif;
  font-weight: normal; }
