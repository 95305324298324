.exhibition_header_module__heading {
  padding: 0;
  margin: 0;
  font-family: 'Plato Basic', sans-serif;
  font-weight: normal;
  letter-spacing: -0.02em;
  margin: 0;
  text-align: right; }
  .exhibition_header_module__heading:not(:last-child) {
    margin-bottom: 1em; }
  @media all and (max-width: 767px) {
    .exhibition_header_module__heading {
      font-size: 3.84rem;
      line-height: 4.16rem; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .exhibition_header_module__heading {
      font-size: 5.76rem;
      line-height: 6.24rem; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .exhibition_header_module__heading {
      font-size: 7.68rem;
      line-height: 8.32rem; } }
  @media (min-width: 1200px) {
    .exhibition_header_module__heading {
      font-size: 9.6rem;
      line-height: 10.4rem; } }
  @media (min-width: 1681px) {
    .exhibition_header_module__heading {
      font-size: 9.6rem;
      line-height: 10.4rem; } }
  .exhibition_header_module__heading:not(:last-child) {
    margin: 0; }
  [data-in-exhibition-group] .exhibition_header_module__heading .exhibition_header_module__heading__title,
  [data-in-exhibition-group] .exhibition_header_module__heading .exhibition_header_module__heading__subtitle {
    text-align: right; }

[data-identity="1.5"] .exhibition_header_module__heading {
  font-family: 'Plato Regular', sans-serif;
  font-weight: normal;
  font-style: italic;
  text-align: left; }
