@import 'partials';

$breakpoints: (xs: (columns: 4, exclusive_query: "all and (max-width: 767px)", query: "all"), s: (columns: 6, exclusive_query: "(min-width: 768px) and (max-width: 991px)", query: "(min-width: 768px)"), m: (columns: 6, exclusive_query: "(min-width: 992px) and (max-width: 1199px)", query: "(min-width: 992px)"), l: (columns: 8, exclusive_query: "(min-width: 1200px)", query: "(min-width: 1200px)"), xl: (columns: 8, exclusive_query: "(min-width: 1681px)", query: "(min-width: 1681px)"));
$modulor_breakpoints: (xs: (columns: 4, query: "all", exclusive_query: "all and (max-width: 1023px)"), m: (columns: 6, query: "all and (min-width: 1024px)", exclusive_query: "(min-width: 1024px) and (max-width: 1679px)"), l: (columns: 8, query: "all and (min-width: 1680px)", exclusive_query: "(min-width: 1680px) and (max-width: 1919px)"), xl: (columns: 10, query: "all and (min-width: 1920px)", exclusive_query: "all and (min-width: 1920px)"));
$paths: (about: O-Nas, bistro: Bistro, children_and_families: Prostor/Prostor-Pro-Deti-A-Rodiny, cinema: Kino, clubs: Prostor/Prostor-Pro-Druziny-Stacionare-Nebo-Kluby, community: Prostor, contact: Pro-Navstevniky, education_elementary_schools: Prostor/Pro-Skoly/Zakladni, education_high_schools: Prostor/Pro-Skoly/Stredni, education_kindergartens: Prostor/Pro-Skoly/Materske, education_teachers: Prostor/Pro-Skoly/Pedagog, education: Prostor/Pro-Skoly, email_signature_generator: Email, exhibitions_archive: Vystavy/Archiv, exhibitions: Vystavy, garden: Zahrada, identity: Identita, instagram_generator: Instagram, interventions_archive: Intervence/Archiv, interventions: Intervence, kavarna: Kavarna, library: Knihovna, materialova_knihovna: Materialova-Knihovna, news_archive: Aktuality/Archiv, news: Aktuality, norma_2019: NORMA/2019, norma_2020: NORMA/2020, norma: NORMA, octopus_press_coming_soon: Octopus-Press/Coming-Soon, octopus_press: Octopus-Press, plato_bauhaus: O-Nas/Plato-Bauhaus, press: Press, program_archiv: Program/Archiv, program: Program, projects_archive: Projekty/Archiv, projects: Projekty, residence: Rezidence, root: Index, screens: Screens, rostliny: Rostliny, search: Search, senior_clubs: Prostor/Prostor-Pro-Druziny-Stacionare-Nebo-Kluby/Kluby-Senioru, shared: Sdilene, shop: Prodejna, stage: Stage, stationary: Prostor/Prostor-Pro-Druziny-Stacionare-Nebo-Kluby/Stacionare, students_and_artists: Prostor/Prostor-Pro-Studenty-A-Umelce, teenagers: Prostor/Prostor-Pro-Teenagery, your_events: Prostor/Prostor-Pro-Komunitu, zahrada_pritomnosti: Zahrada-Pritomnosti);
$modulor_paths: (login: Login, logout: Logout, admin: Admin, profile: Admin/Profile, master_pages: Admin/Master-Pages, template_pages: Admin/Template-Pages, master_page_root: Admin/Master-Pages/Root, system: System, access_denied: System/Access-Denied, activity_list: System/Activity-List, attachment_list: System/Attachment-List, error_404: System/404, new_page: System/New-Page, password_reset: System/Password-Reset, registration: System/Registration, permission_list: System/Permission-List);
body[data-aqi-level] {
  .modulor_page__is_master[data-permalink="Footer"] {
    position: relative;

    &:after,
    &:before {
      content: " ";
      display: none;

      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      max-width: 100%;
      height: 100%;
      min-height: 100vh;
      z-index: z-index(aqi);

      user-select: none;
      pointer-events: none;

      opacity: 0;
      transition: opacity 1s ease-in-out;
    }

    // smog
    &:after {
      background-color: color(black);
    }

    // saturation
    &:before {
      background-color: color(white);
      mix-blend-mode: saturation;
    }
  }
}

body[data-aqi-level="3"] {
  .modulor_page__is_master[data-permalink="Footer"]:after,
  .modulor_page__is_master[data-permalink="Footer"]:before { display: block; }
  .modulor_page__is_master[data-permalink="Footer"]:after { opacity: 0.1; } // smog
  .modulor_page__is_master[data-permalink="Footer"]:before { opacity: 0.2; } // saturation
}

body[data-aqi-level="4"] {
  .modulor_page__is_master[data-permalink="Footer"]:after,
  .modulor_page__is_master[data-permalink="Footer"]:before { display: block; }
  .modulor_page__is_master[data-permalink="Footer"]:after { opacity: 0.2; } // smog
  .modulor_page__is_master[data-permalink="Footer"]:before { opacity: 0.4; } // saturation
}

body[data-aqi-level="5"] {
  .modulor_page__is_master[data-permalink="Footer"]:after,
  .modulor_page__is_master[data-permalink="Footer"]:before { display: block; }
  .modulor_page__is_master[data-permalink="Footer"]:after { opacity: 0.3; } // smog
  .modulor_page__is_master[data-permalink="Footer"]:before { opacity: 0.6; } // saturation
}

body[data-aqi-level="6"] {
  .modulor_page__is_master[data-permalink="Footer"]:after,
  .modulor_page__is_master[data-permalink="Footer"]:before { display: block; }
  .modulor_page__is_master[data-permalink="Footer"]:after { opacity: 0.4; } // smog
  .modulor_page__is_master[data-permalink="Footer"]:before { opacity: 0.8; } // saturation
}

body[data-aqi-level="7"] {
  .modulor_page__is_master[data-permalink="Footer"]:after,
  .modulor_page__is_master[data-permalink="Footer"]:before { display: block; }
  .modulor_page__is_master[data-permalink="Footer"]:after { opacity: 0.5; } // smog
  .modulor_page__is_master[data-permalink="Footer"]:before { opacity: 1; } // saturation
}
