.event_header_module__event_type {
  margin: 0;
  padding: 0;
  font-family: 'Plato Text', sans-serif;
  font-weight: normal;
  display: inline-block;
  white-space: nowrap; }
  .event_header_module__event_type:not(:last-child) {
    margin-bottom: 1em; }

[data-identity="1.5"] .event_header_module__event_type {
  font-family: 'Plato Medium', sans-serif;
  font-weight: normal; }
