.structure_module__waqi {
  margin: 0;
  padding: 0;
  font-family: 'Plato Text', sans-serif;
  font-weight: normal;
  letter-spacing: 0.04em;
  position: relative;
  text-align: right; }
  .structure_module__waqi:not(:last-child) {
    margin-bottom: 1em; }
  @media all and (max-width: 767px) {
    .structure_module__waqi {
      font-size: 1.8rem;
      line-height: 2.52rem; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .structure_module__waqi {
      font-size: 1.6rem;
      line-height: 2.24rem; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .structure_module__waqi {
      font-size: 1.8rem;
      line-height: 2.52rem; } }
  @media (min-width: 1200px) {
    .structure_module__waqi {
      font-size: 2rem;
      line-height: 2.8rem; } }
  @media (min-width: 1681px) {
    .structure_module__waqi {
      font-size: 2rem;
      line-height: 2.8rem; } }

[data-aqi-level="1"] .structure_module__aqi,
[data-aqi-level="2"] .structure_module__aqi {
  color: lime; }

[data-aqi-level="3"] .structure_module__aqi,
[data-aqi-level="4"] .structure_module__aqi {
  color: #ff6400; }

[data-aqi-level="5"] .structure_module__aqi,
[data-aqi-level="6"] .structure_module__aqi,
[data-aqi-level="7"] .structure_module__aqi {
  color: #fa0000; }

.structure_module__waqi__credit {
  letter-spacing: 0.025em;
  color: #afafaf; }
  @media all and (max-width: 767px) {
    .structure_module__waqi__credit {
      font-size: 1.26rem;
      line-height: 1.8rem; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .structure_module__waqi__credit {
      font-size: 1.12rem;
      line-height: 1.6rem; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .structure_module__waqi__credit {
      font-size: 1.4rem;
      line-height: 2rem; } }
  @media (min-width: 1200px) {
    .structure_module__waqi__credit {
      font-size: 1.4rem;
      line-height: 2rem; } }
  @media (min-width: 1681px) {
    .structure_module__waqi__credit {
      font-size: 1.4rem;
      line-height: 2rem; } }
  .structure_module__waqi__credit .day.a,
  .structure_module__waqi__credit .day.a + .sep {
    display: none; }
  .structure_module__waqi__credit .whole_hour.cs .hours + .sep,
  .structure_module__waqi__credit .whole_hour.cs .minutes.M,
  .structure_module__waqi__credit .whole_hour.cs .minutes + .sep {
    display: none; }
  .structure_module__waqi__credit .whole_hour.en .hours + .sep,
  .structure_module__waqi__credit .whole_hour.en .minutes.M {
    display: none; }
  .structure_module__waqi__credit .current_year .month.m + .sep,
  .structure_module__waqi__credit .current_year .year.Y {
    display: none; }
  .structure_module__waqi__credit .date_range:not(.current_year) .current_year .month.m + .sep,
  .structure_module__waqi__credit .date_range:not(.current_year) .current_year .year.Y {
    display: initial; }
