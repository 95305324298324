@charset "UTF-8";
@font-face {
  font-family: "Plato Basic";
  font-style: normal;
  font-weight: normal;
  src: url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fYmFzaWNfMTUwMTIwMjAub3RmIl0sWyJwIiwibm9ybWFsaXplX25hbWVzIl0sWyJwIiwiZW5jb2RlIiwiZW90Il1d/plato_basic_15012020.eot?sha=4ff773d717192af2");
  src: local("☺"), url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fYmFzaWNfMTUwMTIwMjAub3RmIl0sWyJwIiwibm9ybWFsaXplX25hbWVzIl0sWyJwIiwiZW5jb2RlIiwiZW90Il1d/plato_basic_15012020.eot?sha=4ff773d717192af2") format("embedded-opentype"), url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fYmFzaWNfMTUwMTIwMjAub3RmIl0sWyJwIiwibm9ybWFsaXplX25hbWVzIl0sWyJwIiwiZW5jb2RlIiwid29mZjIiXV0/plato_basic_15012020.woff2?sha=eeba2dd402ccba03") format("woff2"), url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fYmFzaWNfMTUwMTIwMjAub3RmIl0sWyJwIiwibm9ybWFsaXplX25hbWVzIl0sWyJwIiwiZW5jb2RlIiwid29mZiJdXQ/plato_basic_15012020.woff?sha=5388eb038091f399") format("woff"), url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fYmFzaWNfMTUwMTIwMjAub3RmIl0sWyJwIiwibm9ybWFsaXplX25hbWVzIl0sWyJwIiwiZW5jb2RlIiwidHRmIl0sWyJwIiwidHRmX2F1dG9oaW50Il1d/plato_basic_15012020.ttf?sha=fef96d52e67ea31d") format("truetype"), url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fYmFzaWNfMTUwMTIwMjAub3RmIl0sWyJwIiwibm9ybWFsaXplX25hbWVzIl0sWyJwIiwiZW5jb2RlIiwic3ZnIl1d/plato_basic_15012020.svg?sha=128573b3e1cacba5") format("svg"); }

.plato_basic {
  font-family: "Plato Basic"; }

@font-face {
  font-family: "Plato Outline";
  font-style: normal;
  font-weight: normal;
  src: url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fb3V0bGluZV8xMDExMjAyMC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJlb3QiXV0/plato_outline_10112020.eot?sha=51291150262464a2");
  src: local("☺"), url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fb3V0bGluZV8xMDExMjAyMC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJlb3QiXV0/plato_outline_10112020.eot?sha=51291150262464a2") format("embedded-opentype"), url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fb3V0bGluZV8xMDExMjAyMC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJ3b2ZmMiJdXQ/plato_outline_10112020.woff2?sha=976afba460a81578") format("woff2"), url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fb3V0bGluZV8xMDExMjAyMC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJ3b2ZmIl1d/plato_outline_10112020.woff?sha=e449dd0e6cfb7f6a") format("woff"), url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fb3V0bGluZV8xMDExMjAyMC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJ0dGYiXSxbInAiLCJ0dGZfYXV0b2hpbnQiXV0/plato_outline_10112020.ttf?sha=b389d3c0d81f5fca") format("truetype"), url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fb3V0bGluZV8xMDExMjAyMC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJzdmciXV0/plato_outline_10112020.svg?sha=491b7521550bf987") format("svg"); }

.plato_outline {
  font-family: "Plato Outline"; }

@font-face {
  font-family: "Plato Text";
  font-style: normal;
  font-weight: normal;
  src: url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fdGV4dF8xNTAxMjAyMC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJlb3QiXV0/plato_text_15012020.eot?sha=5f4aae089146b01b");
  src: local("☺"), url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fdGV4dF8xNTAxMjAyMC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJlb3QiXV0/plato_text_15012020.eot?sha=5f4aae089146b01b") format("embedded-opentype"), url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fdGV4dF8xNTAxMjAyMC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJ3b2ZmMiJdXQ/plato_text_15012020.woff2?sha=cd76d75dea1ad9a6") format("woff2"), url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fdGV4dF8xNTAxMjAyMC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJ3b2ZmIl1d/plato_text_15012020.woff?sha=9f24d1141d247a9b") format("woff"), url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fdGV4dF8xNTAxMjAyMC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJ0dGYiXSxbInAiLCJ0dGZfYXV0b2hpbnQiXV0/plato_text_15012020.ttf?sha=e945e5ea84313f9f") format("truetype"), url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fdGV4dF8xNTAxMjAyMC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJzdmciXV0/plato_text_15012020.svg?sha=e478ced930719b11") format("svg"); }

.plato_text {
  font-family: "Plato Text"; }

@font-face {
  font-family: "Plato Regular";
  font-style: normal;
  font-weight: normal;
  src: url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fcmVndWxhcl8xMzA5MjAyMi5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJlb3QiXV0/plato_regular_13092022.eot?sha=7f9f2f15bdeab1c3");
  src: local("☺"), url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fcmVndWxhcl8xMzA5MjAyMi5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJlb3QiXV0/plato_regular_13092022.eot?sha=7f9f2f15bdeab1c3") format("embedded-opentype"), url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fcmVndWxhcl8xMzA5MjAyMi5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJ3b2ZmMiJdXQ/plato_regular_13092022.woff2?sha=752f0622d7d9a406") format("woff2"), url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fcmVndWxhcl8xMzA5MjAyMi5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJ3b2ZmIl1d/plato_regular_13092022.woff?sha=f2318083225761de") format("woff"), url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fcmVndWxhcl8xMzA5MjAyMi5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJ0dGYiXSxbInAiLCJ0dGZfYXV0b2hpbnQiXV0/plato_regular_13092022.ttf?sha=1498d9a74f424555") format("truetype"), url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fcmVndWxhcl8xMzA5MjAyMi5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJzdmciXV0/plato_regular_13092022.svg?sha=0643f110cf01f2bf") format("svg"); }

.plato_regular {
  font-family: "Plato Regular"; }

@font-face {
  font-family: "Plato Regular";
  font-style: italic;
  font-weight: normal;
  src: url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fcmVndWxhcl9pdGFsaWNfMTMwOTIwMjIub3RmIl0sWyJwIiwibm9ybWFsaXplX25hbWVzIl0sWyJwIiwiZW5jb2RlIiwiZW90Il1d/plato_regular_italic_13092022.eot?sha=60772b996e431534");
  src: local("☺"), url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fcmVndWxhcl9pdGFsaWNfMTMwOTIwMjIub3RmIl0sWyJwIiwibm9ybWFsaXplX25hbWVzIl0sWyJwIiwiZW5jb2RlIiwiZW90Il1d/plato_regular_italic_13092022.eot?sha=60772b996e431534") format("embedded-opentype"), url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fcmVndWxhcl9pdGFsaWNfMTMwOTIwMjIub3RmIl0sWyJwIiwibm9ybWFsaXplX25hbWVzIl0sWyJwIiwiZW5jb2RlIiwid29mZjIiXV0/plato_regular_italic_13092022.woff2?sha=daa71b5b2b00e3ed") format("woff2"), url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fcmVndWxhcl9pdGFsaWNfMTMwOTIwMjIub3RmIl0sWyJwIiwibm9ybWFsaXplX25hbWVzIl0sWyJwIiwiZW5jb2RlIiwid29mZiJdXQ/plato_regular_italic_13092022.woff?sha=44e4c727d6dafe9f") format("woff"), url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fcmVndWxhcl9pdGFsaWNfMTMwOTIwMjIub3RmIl0sWyJwIiwibm9ybWFsaXplX25hbWVzIl0sWyJwIiwiZW5jb2RlIiwidHRmIl0sWyJwIiwidHRmX2F1dG9oaW50Il1d/plato_regular_italic_13092022.ttf?sha=e85a40a14ec89c59") format("truetype"), url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fcmVndWxhcl9pdGFsaWNfMTMwOTIwMjIub3RmIl0sWyJwIiwibm9ybWFsaXplX25hbWVzIl0sWyJwIiwiZW5jb2RlIiwic3ZnIl1d/plato_regular_italic_13092022.svg?sha=9ff89840d9c0e530") format("svg"); }

.plato_regular_italic {
  font-family: "Plato Regular"; }

@font-face {
  font-family: "Plato Medium";
  font-style: normal;
  font-weight: normal;
  src: url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fbWVkaXVtXzEzMDkyMDIyLm90ZiJdLFsicCIsIm5vcm1hbGl6ZV9uYW1lcyJdLFsicCIsImVuY29kZSIsImVvdCJdXQ/plato_medium_13092022.eot?sha=59efc673a05cb14d");
  src: local("☺"), url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fbWVkaXVtXzEzMDkyMDIyLm90ZiJdLFsicCIsIm5vcm1hbGl6ZV9uYW1lcyJdLFsicCIsImVuY29kZSIsImVvdCJdXQ/plato_medium_13092022.eot?sha=59efc673a05cb14d") format("embedded-opentype"), url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fbWVkaXVtXzEzMDkyMDIyLm90ZiJdLFsicCIsIm5vcm1hbGl6ZV9uYW1lcyJdLFsicCIsImVuY29kZSIsIndvZmYyIl1d/plato_medium_13092022.woff2?sha=ef7bdcd7465ee36a") format("woff2"), url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fbWVkaXVtXzEzMDkyMDIyLm90ZiJdLFsicCIsIm5vcm1hbGl6ZV9uYW1lcyJdLFsicCIsImVuY29kZSIsIndvZmYiXV0/plato_medium_13092022.woff?sha=7a65c12ace21db2b") format("woff"), url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fbWVkaXVtXzEzMDkyMDIyLm90ZiJdLFsicCIsIm5vcm1hbGl6ZV9uYW1lcyJdLFsicCIsImVuY29kZSIsInR0ZiJdLFsicCIsInR0Zl9hdXRvaGludCJdXQ/plato_medium_13092022.ttf?sha=70f3ff5b7dc60612") format("truetype"), url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fbWVkaXVtXzEzMDkyMDIyLm90ZiJdLFsicCIsIm5vcm1hbGl6ZV9uYW1lcyJdLFsicCIsImVuY29kZSIsInN2ZyJdXQ/plato_medium_13092022.svg?sha=d51aa3729ba29d48") format("svg"); }

.plato_medium {
  font-family: "Plato Medium"; }

@font-face {
  font-family: "Plato Basic";
  font-style: normal;
  font-weight: normal;
  src: url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fYmFzaWNfMDIwMTIwMjAub3RmIl0sWyJwIiwibm9ybWFsaXplX25hbWVzIl0sWyJwIiwiZW5jb2RlIiwiZW90Il1d/plato_basic_02012020.eot?sha=7939bf0b1d3447cb");
  src: local("☺"), url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fYmFzaWNfMDIwMTIwMjAub3RmIl0sWyJwIiwibm9ybWFsaXplX25hbWVzIl0sWyJwIiwiZW5jb2RlIiwiZW90Il1d/plato_basic_02012020.eot?sha=7939bf0b1d3447cb") format("embedded-opentype"), url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fYmFzaWNfMDIwMTIwMjAub3RmIl0sWyJwIiwibm9ybWFsaXplX25hbWVzIl0sWyJwIiwiZW5jb2RlIiwid29mZjIiXV0/plato_basic_02012020.woff2?sha=d3b94ce4a835dfac") format("woff2"), url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fYmFzaWNfMDIwMTIwMjAub3RmIl0sWyJwIiwibm9ybWFsaXplX25hbWVzIl0sWyJwIiwiZW5jb2RlIiwid29mZiJdXQ/plato_basic_02012020.woff?sha=c539c095639d80de") format("woff"), url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fYmFzaWNfMDIwMTIwMjAub3RmIl0sWyJwIiwibm9ybWFsaXplX25hbWVzIl0sWyJwIiwiZW5jb2RlIiwidHRmIl0sWyJwIiwidHRmX2F1dG9oaW50Il1d/plato_basic_02012020.ttf?sha=59d28b49920a9b4a") format("truetype"), url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fYmFzaWNfMDIwMTIwMjAub3RmIl0sWyJwIiwibm9ybWFsaXplX25hbWVzIl0sWyJwIiwiZW5jb2RlIiwic3ZnIl1d/plato_basic_02012020.svg?sha=53bbb4f19ec2669e") format("svg"); }

@font-face {
  font-family: "Plato Outline";
  font-style: normal;
  font-weight: normal;
  src: url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fb3V0bGluZV8wMjAxMjAyMC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJlb3QiXV0/plato_outline_02012020.eot?sha=cae963fc2bc75244");
  src: local("☺"), url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fb3V0bGluZV8wMjAxMjAyMC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJlb3QiXV0/plato_outline_02012020.eot?sha=cae963fc2bc75244") format("embedded-opentype"), url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fb3V0bGluZV8wMjAxMjAyMC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJ3b2ZmMiJdXQ/plato_outline_02012020.woff2?sha=41b995fce782ed9f") format("woff2"), url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fb3V0bGluZV8wMjAxMjAyMC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJ3b2ZmIl1d/plato_outline_02012020.woff?sha=8a2a8da85b79b108") format("woff"), url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fb3V0bGluZV8wMjAxMjAyMC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJ0dGYiXSxbInAiLCJ0dGZfYXV0b2hpbnQiXV0/plato_outline_02012020.ttf?sha=4fcbb9b33f71a467") format("truetype"), url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fb3V0bGluZV8wMjAxMjAyMC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJzdmciXV0/plato_outline_02012020.svg?sha=35ab03568e620531") format("svg"); }

@font-face {
  font-family: "Plato Text";
  font-style: normal;
  font-weight: normal;
  src: url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fdGV4dF8wMjAxMjAyMC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJlb3QiXV0/plato_text_02012020.eot?sha=f4205aa923a1518e");
  src: local("☺"), url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fdGV4dF8wMjAxMjAyMC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJlb3QiXV0/plato_text_02012020.eot?sha=f4205aa923a1518e") format("embedded-opentype"), url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fdGV4dF8wMjAxMjAyMC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJ3b2ZmMiJdXQ/plato_text_02012020.woff2?sha=124716db416a88d1") format("woff2"), url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fdGV4dF8wMjAxMjAyMC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJ3b2ZmIl1d/plato_text_02012020.woff?sha=f720e3582a5b83c1") format("woff"), url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fdGV4dF8wMjAxMjAyMC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJ0dGYiXSxbInAiLCJ0dGZfYXV0b2hpbnQiXV0/plato_text_02012020.ttf?sha=8df3b3b884e29432") format("truetype"), url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fdGV4dF8wMjAxMjAyMC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJzdmciXV0/plato_text_02012020.svg?sha=58e50a9372fe1ff7") format("svg"); }

@font-face {
  font-family: "Plato Basic Compat";
  font-style: normal;
  font-weight: normal;
  src: url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fYmFzaWNfMjUwNDIwMTkub3RmIl0sWyJwIiwibm9ybWFsaXplX25hbWVzIl0sWyJwIiwiZW5jb2RlIiwiZW90Il1d/plato_basic_25042019.eot?sha=ccab7179001c224a");
  src: local("☺"), url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fYmFzaWNfMjUwNDIwMTkub3RmIl0sWyJwIiwibm9ybWFsaXplX25hbWVzIl0sWyJwIiwiZW5jb2RlIiwiZW90Il1d/plato_basic_25042019.eot?sha=ccab7179001c224a") format("embedded-opentype"), url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fYmFzaWNfMjUwNDIwMTkub3RmIl0sWyJwIiwibm9ybWFsaXplX25hbWVzIl0sWyJwIiwiZW5jb2RlIiwid29mZjIiXV0/plato_basic_25042019.woff2?sha=1e169b0fb2a7a62b") format("woff2"), url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fYmFzaWNfMjUwNDIwMTkub3RmIl0sWyJwIiwibm9ybWFsaXplX25hbWVzIl0sWyJwIiwiZW5jb2RlIiwid29mZiJdXQ/plato_basic_25042019.woff?sha=a0df40723db3204c") format("woff"), url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fYmFzaWNfMjUwNDIwMTkub3RmIl0sWyJwIiwibm9ybWFsaXplX25hbWVzIl0sWyJwIiwiZW5jb2RlIiwidHRmIl0sWyJwIiwidHRmX2F1dG9oaW50Il1d/plato_basic_25042019.ttf?sha=c8449cae110203e0") format("truetype"), url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fYmFzaWNfMjUwNDIwMTkub3RmIl0sWyJwIiwibm9ybWFsaXplX25hbWVzIl0sWyJwIiwiZW5jb2RlIiwic3ZnIl1d/plato_basic_25042019.svg?sha=a0fe89de7ef9cd9f") format("svg"); }

@font-face {
  font-family: "Plato Outline Compat";
  font-style: normal;
  font-weight: normal;
  src: url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fb3V0bGluZV8yNTA0MjAxOS5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJlb3QiXV0/plato_outline_25042019.eot?sha=167a36ef9bb3bc60");
  src: local("☺"), url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fb3V0bGluZV8yNTA0MjAxOS5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJlb3QiXV0/plato_outline_25042019.eot?sha=167a36ef9bb3bc60") format("embedded-opentype"), url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fb3V0bGluZV8yNTA0MjAxOS5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJ3b2ZmMiJdXQ/plato_outline_25042019.woff2?sha=a05e0710faa4c267") format("woff2"), url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fb3V0bGluZV8yNTA0MjAxOS5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJ3b2ZmIl1d/plato_outline_25042019.woff?sha=837d7c9c213853e9") format("woff"), url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fb3V0bGluZV8yNTA0MjAxOS5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJ0dGYiXSxbInAiLCJ0dGZfYXV0b2hpbnQiXV0/plato_outline_25042019.ttf?sha=1dfcde22e2fec97f") format("truetype"), url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fb3V0bGluZV8yNTA0MjAxOS5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJzdmciXV0/plato_outline_25042019.svg?sha=6e00bdfbcc8ce690") format("svg"); }

@font-face {
  font-family: "Plato Text Compat";
  font-style: normal;
  font-weight: normal;
  src: url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fdGV4dF8yNTA0MjAxOS5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJlb3QiXV0/plato_text_25042019.eot?sha=a98b3a79687f08e9");
  src: local("☺"), url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fdGV4dF8yNTA0MjAxOS5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJlb3QiXV0/plato_text_25042019.eot?sha=a98b3a79687f08e9") format("embedded-opentype"), url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fdGV4dF8yNTA0MjAxOS5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJ3b2ZmMiJdXQ/plato_text_25042019.woff2?sha=a1e3ff56fb7ede74") format("woff2"), url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fdGV4dF8yNTA0MjAxOS5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJ3b2ZmIl1d/plato_text_25042019.woff?sha=44a1549ed0af8982") format("woff"), url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fdGV4dF8yNTA0MjAxOS5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJ0dGYiXSxbInAiLCJ0dGZfYXV0b2hpbnQiXV0/plato_text_25042019.ttf?sha=e08a5e4f019fe2ab") format("truetype"), url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fdGV4dF8yNTA0MjAxOS5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJzdmciXV0/plato_text_25042019.svg?sha=2a6186e1f9b685f1") format("svg"); }
