@import 'partials';

$breakpoints: (xs: (columns: 4, exclusive_query: "all and (max-width: 767px)", query: "all"), s: (columns: 6, exclusive_query: "(min-width: 768px) and (max-width: 991px)", query: "(min-width: 768px)"), m: (columns: 6, exclusive_query: "(min-width: 992px) and (max-width: 1199px)", query: "(min-width: 992px)"), l: (columns: 8, exclusive_query: "(min-width: 1200px)", query: "(min-width: 1200px)"), xl: (columns: 8, exclusive_query: "(min-width: 1681px)", query: "(min-width: 1681px)"));
$modulor_breakpoints: (xs: (columns: 4, query: "all", exclusive_query: "all and (max-width: 1023px)"), m: (columns: 6, query: "all and (min-width: 1024px)", exclusive_query: "(min-width: 1024px) and (max-width: 1679px)"), l: (columns: 8, query: "all and (min-width: 1680px)", exclusive_query: "(min-width: 1680px) and (max-width: 1919px)"), xl: (columns: 10, query: "all and (min-width: 1920px)", exclusive_query: "all and (min-width: 1920px)"));
$paths: (about: O-Nas, bistro: Bistro, children_and_families: Prostor/Prostor-Pro-Deti-A-Rodiny, cinema: Kino, clubs: Prostor/Prostor-Pro-Druziny-Stacionare-Nebo-Kluby, community: Prostor, contact: Pro-Navstevniky, education_elementary_schools: Prostor/Pro-Skoly/Zakladni, education_high_schools: Prostor/Pro-Skoly/Stredni, education_kindergartens: Prostor/Pro-Skoly/Materske, education_teachers: Prostor/Pro-Skoly/Pedagog, education: Prostor/Pro-Skoly, email_signature_generator: Email, exhibitions_archive: Vystavy/Archiv, exhibitions: Vystavy, garden: Zahrada, identity: Identita, instagram_generator: Instagram, interventions_archive: Intervence/Archiv, interventions: Intervence, kavarna: Kavarna, library: Knihovna, materialova_knihovna: Materialova-Knihovna, news_archive: Aktuality/Archiv, news: Aktuality, norma_2019: NORMA/2019, norma_2020: NORMA/2020, norma: NORMA, octopus_press_coming_soon: Octopus-Press/Coming-Soon, octopus_press: Octopus-Press, plato_bauhaus: O-Nas/Plato-Bauhaus, press: Press, program_archiv: Program/Archiv, program: Program, projects_archive: Projekty/Archiv, projects: Projekty, residence: Rezidence, root: Index, screens: Screens, rostliny: Rostliny, search: Search, senior_clubs: Prostor/Prostor-Pro-Druziny-Stacionare-Nebo-Kluby/Kluby-Senioru, shared: Sdilene, shop: Prodejna, stage: Stage, stationary: Prostor/Prostor-Pro-Druziny-Stacionare-Nebo-Kluby/Stacionare, students_and_artists: Prostor/Prostor-Pro-Studenty-A-Umelce, teenagers: Prostor/Prostor-Pro-Teenagery, your_events: Prostor/Prostor-Pro-Komunitu, zahrada_pritomnosti: Zahrada-Pritomnosti);
$modulor_paths: (login: Login, logout: Logout, admin: Admin, profile: Admin/Profile, master_pages: Admin/Master-Pages, template_pages: Admin/Template-Pages, master_page_root: Admin/Master-Pages/Root, system: System, access_denied: System/Access-Denied, activity_list: System/Activity-List, attachment_list: System/Attachment-List, error_404: System/404, new_page: System/New-Page, password_reset: System/Password-Reset, registration: System/Registration, permission_list: System/Permission-List);
.octopus_press_article_list_module__content[data-view-option="index"] {
  .octopus_press_article_page__row {
    border-bottom: .1rem solid;

    padding-top: .5em;
    padding-bottom: .5em;
  }

  .octopus_press_article_page__row[data-article-type="video"] {
    background-color: color(black);
    color: color(white);
  }

  .octopus_press_article_page__row[data-article-type="image"],
  .octopus_press_article_page__row[data-article-type="audio"] {
    background-color: color(gray);
  }

  .octopus_press_article_page__row__link {
    text-decoration: none;
    color: inherit;
  }

  .octopus_press_article_list_module__head,
  .octopus_press_article_list_module__index__group_key,
  .octopus_press_article_page__row__thumbnail {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 5ch .5fr .5fr;
    grid-column-gap: 1em;

    @include breakpoints(l) {
      grid-template-columns: 1fr 1fr 1fr 5ch .5fr 1fr;
    }

    @include breakpoints(s m) {
      grid-template-columns: 1fr 1fr 5ch .5fr 1fr;
    }

    @include breakpoints(xs) {
      grid-template-columns: 1fr 1fr;
    }
  }

  .octopus_press_article_page__row__author {
    @include breakpoints {
      padding-left: $web-module-padding * 1.5;
    }

    @include breakpoints(xs s) {
      padding-left: $web-module-padding;
    }
  }

  .octopus_press_article_page__row__title {
    @include breakpoints(xs) {
      padding-right: $web-module-padding;
    }
  }

  .octopus_press_article_page__row__languages {
    margin: 0;
    padding: 0;
    list-style: none;

    margin-top: calc(-1 * (.2rem + .05em + .15em));
    margin-bottom: calc(-1 * (.2rem + .05em + .15em));
  }

  .octopus_press_article_page__row__language {
    display: inline-block;

    text-transform: uppercase;

    border: .2rem solid;
    border-radius: 1em;

    padding-top: .05em;
    padding-left: .5em;
    padding-right: .5em;

    margin-top: .15em;
    margin-bottom: .15em;

    @include breakpoints(xs s m) {
      border: .1rem solid;
    }

    &:not(:last-child) {
      margin-right: .1em;
    }
  }

  .octopus_press_article_page__row__related_pages {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .octopus_press_article_page__row__related_page {
    font-style: italic;
  }

  .octopus_press_article_page__row__article_type {}

  .octopus_press_article_page__row__article_type__pill {
    display: inline-block;

    border: .2rem solid;
    border-radius: 1em;

    padding-top: .05em;
    padding-left: .5em;
    padding-right: .5em;

    margin-top: calc(-1 * (.2rem + .05em));

    @include breakpoints(xs s m) {
      border: .1rem solid;
    }
  }

  .octopus_press_article_page__row__durations {
    margin: 0;
    padding: 0;
    list-style: none;

    display: inline;
  }

  .octopus_press_article_page__row__duration {
    display: inline;
  }

  .octopus_press_article_page__row__word_counts {
    margin: 0;
    padding: 0;
    list-style: none;

    display: inline;
  }

  .octopus_press_article_page__row__word_count {
    display: inline;
  }

  .octopus_press_article_list_module__head,
  .octopus_press_article_page__row__thumbnail {
    @include breakpoints(xs s m) {
      @include font-size(small);
    }
  }

  .octopus_press_article_page__row__related_pages {
    @include breakpoints(s m) {
      display: none;
    }
  }

  .octopus_press_article_page__row__related_pages,
  .octopus_press_article_page__row__dposted,
  .octopus_press_article_page__row__languages,
  .octopus_press_article_page__row__article_type {
    @include breakpoints(xs) {
      display: none;
    }
  }
}
