.graphic_border_component__root {
  position: absolute; }

[data-placement=bottom] {
  height: 4vmin;
  width: 50vw;
  left: 0;
  bottom: 0; }

[data-placement=right] {
  width: 4vmin;
  height: 100vh;
  right: 0;
  top: 0; }

.graphic_border_component__root[data-placement=right] {
  width: 0%; }

.graphic_border_component__root[data-placement=right][data-animation-play] {
  animation: 1s graphic_border_component__border_in forwards; }

@keyframes graphic_border_component__border_out {
  0% {
    width: 4vmin; }
  100% {
    width: 100%; } }

@keyframes graphic_border_component__border_in {
  0% {
    width: 0%; }
  100% {
    width: 4vmin; } }
