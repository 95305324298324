@import 'partials';

$breakpoints: (xs: (columns: 4, exclusive_query: "all and (max-width: 767px)", query: "all"), s: (columns: 6, exclusive_query: "(min-width: 768px) and (max-width: 991px)", query: "(min-width: 768px)"), m: (columns: 6, exclusive_query: "(min-width: 992px) and (max-width: 1199px)", query: "(min-width: 992px)"), l: (columns: 8, exclusive_query: "(min-width: 1200px)", query: "(min-width: 1200px)"), xl: (columns: 8, exclusive_query: "(min-width: 1681px)", query: "(min-width: 1681px)"));
$modulor_breakpoints: (xs: (columns: 4, query: "all", exclusive_query: "all and (max-width: 1023px)"), m: (columns: 6, query: "all and (min-width: 1024px)", exclusive_query: "(min-width: 1024px) and (max-width: 1679px)"), l: (columns: 8, query: "all and (min-width: 1680px)", exclusive_query: "(min-width: 1680px) and (max-width: 1919px)"), xl: (columns: 10, query: "all and (min-width: 1920px)", exclusive_query: "all and (min-width: 1920px)"));
$paths: (about: O-Nas, bistro: Bistro, children_and_families: Prostor/Prostor-Pro-Deti-A-Rodiny, cinema: Kino, clubs: Prostor/Prostor-Pro-Druziny-Stacionare-Nebo-Kluby, community: Prostor, contact: Pro-Navstevniky, education_elementary_schools: Prostor/Pro-Skoly/Zakladni, education_high_schools: Prostor/Pro-Skoly/Stredni, education_kindergartens: Prostor/Pro-Skoly/Materske, education_teachers: Prostor/Pro-Skoly/Pedagog, education: Prostor/Pro-Skoly, email_signature_generator: Email, exhibitions_archive: Vystavy/Archiv, exhibitions: Vystavy, garden: Zahrada, identity: Identita, instagram_generator: Instagram, interventions_archive: Intervence/Archiv, interventions: Intervence, kavarna: Kavarna, library: Knihovna, materialova_knihovna: Materialova-Knihovna, news_archive: Aktuality/Archiv, news: Aktuality, norma_2019: NORMA/2019, norma_2020: NORMA/2020, norma: NORMA, octopus_press_coming_soon: Octopus-Press/Coming-Soon, octopus_press: Octopus-Press, plato_bauhaus: O-Nas/Plato-Bauhaus, press: Press, program_archiv: Program/Archiv, program: Program, projects_archive: Projekty/Archiv, projects: Projekty, residence: Rezidence, root: Index, screens: Screens, rostliny: Rostliny, search: Search, senior_clubs: Prostor/Prostor-Pro-Druziny-Stacionare-Nebo-Kluby/Kluby-Senioru, shared: Sdilene, shop: Prodejna, stage: Stage, stationary: Prostor/Prostor-Pro-Druziny-Stacionare-Nebo-Kluby/Stacionare, students_and_artists: Prostor/Prostor-Pro-Studenty-A-Umelce, teenagers: Prostor/Prostor-Pro-Teenagery, your_events: Prostor/Prostor-Pro-Komunitu, zahrada_pritomnosti: Zahrada-Pritomnosti);
$modulor_paths: (login: Login, logout: Logout, admin: Admin, profile: Admin/Profile, master_pages: Admin/Master-Pages, template_pages: Admin/Template-Pages, master_page_root: Admin/Master-Pages/Root, system: System, access_denied: System/Access-Denied, activity_list: System/Activity-List, attachment_list: System/Attachment-List, error_404: System/404, new_page: System/New-Page, password_reset: System/Password-Reset, registration: System/Registration, permission_list: System/Permission-List);
.octopus_press_article_header_module__content {
  @media print {
    display: block;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.octopus_press_article_header_module__header {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @include breakpoints {
    grid-column-gap: $web-module-padding * 2;
  }

  grid-template-areas:
    "scene scene"
    "author author"
    "title title"
    "props props"
    "credits description"
    "dposted description";
}

.octopus_press_article_header_module__scene {
  @include plato-basic;
  @include font-size(xxlarge);
  text-align: center;

  grid-area: scene;

  display: none;
}

.octopus_press_article_header_module__author {
  @include plato-outline;
  @include font-size(xxlarge);

  display: inline-block;

  &:after { content: ":"; }

  grid-area: author;
}

.octopus_press_article_header_module__title {
  @include plato-text;
  @include font-size(xxlarge);

  margin: 0;
  margin-bottom: .25em;
  padding: 0;

  grid-area: title;

  @media print {
    margin-bottom: .5em;
  }
}

.octopus_press_article_header_module__props {
  @include plato-text;
  @include font-size(large);

  list-style: none;
  margin: 0;
  padding: 0;

  grid-area: props;
  margin-bottom: .5em;

  @media print { display: none; }
}

.octopus_press_article_header_module__prop {
  display: inline-block;
  margin-bottom: .5em;

  &:not(:last-child) {
    margin-right: .5em;
  }
}

.octopus_press_article_header_module__word_counts,
.octopus_press_article_header_module__durations,
.octopus_press_article_header_module__languages {
  list-style: none;
  margin: 0;
  padding: 0;
}

.octopus_press_article_header_module__word_counts,
.octopus_press_article_header_module__durations {
  display: inline;
}

.octopus_press_article_header_module__word_count,
.octopus_press_article_header_module__duration {
  display: inline;

  &:not(:last-child):after {
    content: ", ";
  }
}

.octopus_press_article_header_module__language,
.octopus_press_article_header_module__current__language {
  text-transform: uppercase;
}

.octopus_press_article_header_module__word_count {
  text-transform: lowercase;
}

.octopus_press_article_header_module__current__language,
.octopus_press_article_header_module__language {
  display: inline-block;

  &:not(:last-child) {
    margin-right: .1em;
  }
}

.octopus_press_article_header_module__pill {
  border: .2rem solid;
  border-radius: 1em;

  padding-top: .05em;
  padding-left: .5em;
  padding-right: .5em;
}

.octopus_press_article_header_module__pill__current {
  border-color: currentColor;
  background-color: color(black);
  color: color(white);
}

[data-article-type="image"] .octopus_press_article_header_module__pill__current,
[data-article-type="audio"] .octopus_press_article_header_module__pill__current {
  border-color: color(gray);
  color: color(gray);
}

.octopus_press_article_header_module__link_to__language {
  color: inherit;
  text-decoration: none;

  &:hover {
    border-color: color(black);
    background-color: color(black);
    color: color(white);
  }
}

.octopus_press_article_header_module__prop__pdf_download {
  @include plato-text;
  @include font-size(large);

  margin-left: auto;
}

.octopus_press_article_header_module__link_to__pdf_download {
  color: inherit;
  text-decoration: none;

  &:hover {
    border-color: color(black);
    background-color: color(black);
    color: color(white);
  }
}

.octopus_press_article_header_module__credits {
  @include plato-text;

  grid-area: credits;

  @media screen {
    @include font-size(default);
  }

  @media print {
    @include font-size(small);
  }
}

.octopus_press_article_header_module__credits a {
  @include underline-for(default, color(black));

  text-decoration: none;
  color: inherit;
}

.octopus_press_article_header_module__credits p {
  margin: 0;
  padding: 0;
  &:not(:last-child) {
    margin-bottom: 1em;
  }
}

.octopus_press_article_header_module__dposted {
  @include plato-text;
  @include font-size(small);
  @include semantic-date-time-tag(hide-year-if-current);
  @include semantic-date-time-tag(hide-day-name);

  grid-area: dposted;
}

.octopus_press_article_header_module__description_wrapper {
  p {
    margin: 0;
    padding: 0
  }
  p:not(:last-child) { margin-bottom: 1em; }

  grid-area: description;
}

.octopus_press_article_header_module__description,
.octopus_press_article_header_module__details {
  @include plato-text;

  em, i { font-style: normal; }

  a {
    color: inherit;
  }

  @media screen {
    @include font-size(default);
  }

  @media print {
    @include font-size(small);
  }
}

html[lang="cs"] {
  .octopus_press_article_header_module__description,
  .octopus_press_article_header_module__details {
    em, i {
      &:before { content: "„" }
      &:after { content: "“" }
    }
  }
}

html[lang="en"] {
  .octopus_press_article_header_module__description,
  .octopus_press_article_header_module__details {
    em, i {
      &:before { content: "“" }
      &:after { content: "”" }
    }
  }
}

.octopus_press_article_header_module__summary {
  list-style: none
}

.octopus_press_article_header_module__summary::-webkit-details-marker {
  display: none;
}

.octopus_press_article_header_module__details:not([open]) {
  .octopus_press_article_header_module__summary {
    &:after {
      content: attr(data-more-str);
      cursor: pointer;
      display: inline-block;

      @media print { display: none; }
    }
  }
}

.octopus_press_article_header_module__details[open] {
  .octopus_press_article_header_module__summary {
    margin-bottom: 1em;
  }
}

[data-identity="1.5"] {
  .octopus_press_article_header_module__author {
    @include plato-regular;
  }

  .octopus_press_article_header_module__title {
    @include plato-medium;
  }

  .octopus_press_article_header_module__props {
    @include plato-medium;
  }

  .octopus_press_article_header_module__credits,
  .octopus_press_article_header_module__description,
  .octopus_press_article_header_module__details,
  .octopus_press_article_header_module__dposted {
    @include plato-regular;
  }
}
