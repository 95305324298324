@import '../variables/typography';

@mixin font-size($size) {
  @if $size ==small {
    letter-spacing: 0.025em;
  }

  @if $size ==screen-small {
    letter-spacing: 0.01em;
  }

  @if $size ==default {
    letter-spacing: 0.04em;
  }

  @if $size ==screen-default {
    letter-spacing: 0.01em;
  }

  @if $size ==large {
    letter-spacing: 0.02em;
  }

  @if $size ==screen-large {
    letter-spacing: 0.02em;
  }

  @if $size ==xxlarge {
    letter-spacing: -0.02em;
  }

  @if $size ==screen-xlarge {
    letter-spacing: -0.02em;
  }

  @include breakpoints {
    font-size: map-deep-get($typography, $size, font-size);
    line-height: map-deep-get($typography, $size, line-height);
  }
}
