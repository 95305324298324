// @include semantic-date-time-tag(hide-day)

@mixin semantic-date-time-tag($type) {
  @if $type == hide-day-name {
    .day.a,
    .day.a + .sep {
      display: none;
    }
  }

  @if $type == hide-day-number {
    .day.d,
    .day.d + .sep {
      display: none;
    }
  }

  @if $type == hide-month {
    .day.d + .sep,
    .month.m,
    .month.m + .sep {
      display: none;
    }
  }

  @if $type == hide-year {
    .cs {
      .month.m + .sep,
      .year.Y,
      .year.Y + .sep {
        display: none;
      }
    }

    .en {
      .month.m + .sep,
      .year.Y {
        display: none;
      }
    }
  }

  @if $type == hide-date {
    @include semantic-date-time-tag(hide-day-name);
    @include semantic-date-time-tag(hide-day-number);
    @include semantic-date-time-tag(hide-month);
    @include semantic-date-time-tag(hide-year);
    @include semantic-date-time-tag(hide-first-str-if-range);
  }

  @if $type == hide-time {
    .cs {
      .year.Y + .sep,
      .hours.H,
      .hours.H + .sep,
      .minutes.M,
      .minutes.M + .str {
        display: none;
      }
    }
    .en {
      .year.Y + .sep,
      .hours.I,
      .hours.I + .sep,
      .minutes.M,
      .minutes.M + .str,
      .ampm {
        display: none;
      }
    }
  }

  @if $type == hide-first-str-if-range {
    .date_range {
      .cs.from {
        .minutes.M + .str {
          display: none;
        }
      }
    }

    .en {
      .year.Y + .sep {
        display: none;
      }
    }

    .date_range.same_meridian {
      .en.from {
        .minutes.M + .sep,
        .ampm {
          display: none;
        }
      }
    }
  }

  @if $type == hide-year-if-current {
    .current_year {
      .month.m + .sep,
      .year.Y {
        display: none;
      }
    }
    .date_range:not(.current_year) {
      .current_year {
        .month.m + .sep,
        .year.Y {
          display: initial;
        }
      }
    }
  }

  @if $type == hide-year-if-same {
    .same_year {
      .from {
        .month.m + .sep,
        .year.Y {
          display: none;
        }
      }
    }
  }

  @if $type == minutes-in-full-hour-as-h {
    .whole_hour.cs {
      .hours + .sep,
      .minutes.M,
      .minutes + .sep {
        display: none;
      }
    }

    .whole_hour.en {
      .hours + .sep,
      .minutes.M {
        display: none;
      }
    }
  }

  @if $type == hide-month-if-same {
    .same_year.same_month {
      .from {
        .day.d + .sep,
        .month,
        .month + .sep {
          display: none;
        }
      }
    }
  }

  @if $type == hide-to-date-if-same-day {
    .same_day {
      .to {
        @include semantic-date-time-tag(hide-date);
      }
    }
  }


  @if $type == prevent-break-in-date {
    .month,
    .month + .sep {
      @include prevent-line-break(after);
    }
  }

  @if $type == prevent-break-in-date {
    .day.a,
    .day.a + .sep,
    .day.d,
    .day.d + .sep,
    .month.m,
    .month.m + .sep,
    .year.Y,
    .year.Y + .sep {
      @include prevent-line-break(after);
    }
  }

  @if $type == prevent-break-in-time {
    .hours,
    .hours + .sep,
    .minutes,
    .minutes + .sep,
    .minutes + .str,
    .minutes + .sep + .ampm {
      @include prevent-line-break(after);
    }
  }
}
