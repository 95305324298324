.project_header_module__dates {
  font-family: 'Plato Text', sans-serif;
  font-weight: normal;
  letter-spacing: 0.04em;
  margin-left: auto;
  margin-right: auto;
  text-align: right; }
  @media all and (max-width: 767px) {
    .project_header_module__dates {
      font-size: 1.8rem;
      line-height: 2.52rem; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .project_header_module__dates {
      font-size: 1.6rem;
      line-height: 2.24rem; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .project_header_module__dates {
      font-size: 1.8rem;
      line-height: 2.52rem; } }
  @media (min-width: 1200px) {
    .project_header_module__dates {
      font-size: 2rem;
      line-height: 2.8rem; } }
  @media (min-width: 1681px) {
    .project_header_module__dates {
      font-size: 2rem;
      line-height: 2.8rem; } }
  .project_header_module__dates .day.a,
  .project_header_module__dates .day.a + .sep {
    display: none; }
  .project_header_module__dates .current_year .month.m + .sep,
  .project_header_module__dates .current_year .year.Y {
    display: none; }
  .project_header_module__dates .date_range:not(.current_year) .current_year .month.m + .sep,
  .project_header_module__dates .date_range:not(.current_year) .current_year .year.Y {
    display: initial; }
  @media (min-width: 1200px) {
    .project_header_module__dates {
      /* l */
      width: 75%; } }
  @media (min-width: 1681px) {
    .project_header_module__dates {
      /* xl */
      width: 75%; } }
  @media all and (max-width: 767px) {
    .project_header_module__dates {
      padding-left: 1.8rem;
      padding-right: 1.8rem; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .project_header_module__dates {
      padding-left: 2.24rem;
      padding-right: 2.24rem; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .project_header_module__dates {
      padding-left: 2.52rem;
      padding-right: 2.52rem; } }
  @media (min-width: 1200px) {
    .project_header_module__dates {
      padding-left: 2.8rem;
      padding-right: 2.8rem; } }
  @media (min-width: 1681px) {
    .project_header_module__dates {
      padding-left: 2.8rem;
      padding-right: 2.8rem; } }

[data-identity="1.5"] .project_header_module__dates {
  font-family: 'Plato Regular', sans-serif;
  font-weight: normal; }
