@charset "UTF-8";
.quick_search_results_component__list {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 2em;
  display: grid;
  grid-template-columns: 1fr;
  gap: 2em; }
  .quick_search_results_component__list > li {
    margin: 0;
    padding: 0; }

.quick_search_results_component__all_results {
  color: inherit;
  text-decoration: none;
  -webkit-print-color-adjust: exact;
  background-size: 100%;
  background-repeat: repeat-x;
  background-position: 100% 0;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 0.01em, black 0.01em, black 0.11em, rgba(0, 0, 0, 0) 0.11em); }
  .quick_search_results_component__all_results::after {
    content: '…'; }

.quick_search_results_component__no_results {
  margin-top: 2em; }
