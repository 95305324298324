@mixin plato-basic {
  font-family: 'Plato Basic', sans-serif;
  font-weight: normal;
}

@mixin plato-text {
  font-family: 'Plato Text', sans-serif;
  font-weight: normal;
}

@mixin plato-outline {
  font-family: 'Plato Outline', sans-serif;
  font-weight: normal;
}

@mixin plato-regular {
  font-family: 'Plato Regular', sans-serif;
  font-weight: normal;
}

@mixin plato-medium {
  font-family: 'Plato Medium', sans-serif;
  font-weight: normal;
}

@mixin a-gothic-regular {
  font-family: 'A Gothic Regular', sans-serif;
  font-weight: normal;
}

@mixin a-gothic-regular-italic {
  font-family: 'A Gothic Regular Italic', sans-serif;
  font-weight: normal;
}
