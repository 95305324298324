@import 'partials';

$breakpoints: (xs: (columns: 4, exclusive_query: "all and (max-width: 767px)", query: "all"), s: (columns: 6, exclusive_query: "(min-width: 768px) and (max-width: 991px)", query: "(min-width: 768px)"), m: (columns: 6, exclusive_query: "(min-width: 992px) and (max-width: 1199px)", query: "(min-width: 992px)"), l: (columns: 8, exclusive_query: "(min-width: 1200px)", query: "(min-width: 1200px)"), xl: (columns: 8, exclusive_query: "(min-width: 1681px)", query: "(min-width: 1681px)"));
$modulor_breakpoints: (xs: (columns: 4, query: "all", exclusive_query: "all and (max-width: 1023px)"), m: (columns: 6, query: "all and (min-width: 1024px)", exclusive_query: "(min-width: 1024px) and (max-width: 1679px)"), l: (columns: 8, query: "all and (min-width: 1680px)", exclusive_query: "(min-width: 1680px) and (max-width: 1919px)"), xl: (columns: 10, query: "all and (min-width: 1920px)", exclusive_query: "all and (min-width: 1920px)"));
$paths: (about: O-Nas, bistro: Bistro, children_and_families: Prostor/Prostor-Pro-Deti-A-Rodiny, cinema: Kino, clubs: Prostor/Prostor-Pro-Druziny-Stacionare-Nebo-Kluby, community: Prostor, contact: Pro-Navstevniky, education_elementary_schools: Prostor/Pro-Skoly/Zakladni, education_high_schools: Prostor/Pro-Skoly/Stredni, education_kindergartens: Prostor/Pro-Skoly/Materske, education_teachers: Prostor/Pro-Skoly/Pedagog, education: Prostor/Pro-Skoly, email_signature_generator: Email, exhibitions_archive: Vystavy/Archiv, exhibitions: Vystavy, garden: Zahrada, identity: Identita, instagram_generator: Instagram, interventions_archive: Intervence/Archiv, interventions: Intervence, kavarna: Kavarna, library: Knihovna, materialova_knihovna: Materialova-Knihovna, news_archive: Aktuality/Archiv, news: Aktuality, norma_2019: NORMA/2019, norma_2020: NORMA/2020, norma: NORMA, octopus_press_coming_soon: Octopus-Press/Coming-Soon, octopus_press: Octopus-Press, plato_bauhaus: O-Nas/Plato-Bauhaus, press: Press, program_archiv: Program/Archiv, program: Program, projects_archive: Projekty/Archiv, projects: Projekty, residence: Rezidence, root: Index, screens: Screens, rostliny: Rostliny, search: Search, senior_clubs: Prostor/Prostor-Pro-Druziny-Stacionare-Nebo-Kluby/Kluby-Senioru, shared: Sdilene, shop: Prodejna, stage: Stage, stationary: Prostor/Prostor-Pro-Druziny-Stacionare-Nebo-Kluby/Stacionare, students_and_artists: Prostor/Prostor-Pro-Studenty-A-Umelce, teenagers: Prostor/Prostor-Pro-Teenagery, your_events: Prostor/Prostor-Pro-Komunitu, zahrada_pritomnosti: Zahrada-Pritomnosti);
$modulor_paths: (login: Login, logout: Logout, admin: Admin, profile: Admin/Profile, master_pages: Admin/Master-Pages, template_pages: Admin/Template-Pages, master_page_root: Admin/Master-Pages/Root, system: System, access_denied: System/Access-Denied, activity_list: System/Activity-List, attachment_list: System/Attachment-List, error_404: System/404, new_page: System/New-Page, password_reset: System/Password-Reset, registration: System/Registration, permission_list: System/Permission-List);
.octopus_press_text_module__content {
  @include font-size(large);

  display: grid;

  @include breakpoints {
    grid-template-columns: repeat(#{$columns}, 1fr);
    grid-column-gap: $web-module-padding * 2;
  }

  @media print {
    display: block;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.octopus_press_text_module__container {
  @include breakpoints(xs) {
    grid-column: 1 / span 4;
  }

  @include breakpoints(m s) {
    grid-column: 2 / span 4;
  }

  @include breakpoints(l) {
    grid-column: 2 / span 6;
  }

  @include breakpoints(xl) {
    grid-column: 3 / span 4;
  }

  grid-column: 3 / span 4;
}

.octopus_press_text_module__title,
.octopus_press_text_module__body {
  @include font-size(large);

  font-family: 'EB Garamond', serif;
  letter-spacing: 0.01em !important;
}

.octopus_press_text_module__title {
  margin: 0;
  padding: 0;
  margin-bottom: 1em;

  a {
    color: inherit;
  }
}

.octopus_press_text_module__body {
  text-decoration-thickness: .06em;
  text-underline-offset: .18em;

  em,
  i {}

  h1,
  h2,
  h3,
  h4 {
    @include font-size(large);

    margin: 0;
    padding: 0;

    &:not(:last-child) {
      margin-bottom: 1em;
    }
  }

  h1:not(:first-child) {
    margin-top: 2em;
  }

  blockquote {
    margin: 0;
    padding: 0;

    @include breakpoints {
      padding-left: $web-module-padding * 2;
    }

    &:not(:last-child) {
      margin-bottom: 1em;
    }
  }

  p {
    margin: 0;
    padding: 0;
  }

  p:not(:last-child) {
    margin-bottom: 1em;
  }

  hr {
    margin: 0;
    padding: 0;
    border: 0;
    border-top: .1rem solid;
    margin-bottom: .5em;
  }

  sup {
    line-height: 0;
  }

  sup a {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  a {
    color: inherit;
  }

  .footnotes {
    @media screen {
      @include font-size(default);
    }

    @media print {
      font-size: 1.4rem !important;
      line-height: 2.0rem !important;
    }

    margin-top: 3em;

    ol {
      margin: 0;
    }

    a {
      color: inherit;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    @media print {
      a {
        text-decoration: none;
      }
    }
  }
}
