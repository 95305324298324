@import 'partials';

$breakpoints: (xs: (columns: 4, exclusive_query: "all and (max-width: 767px)", query: "all"), s: (columns: 6, exclusive_query: "(min-width: 768px) and (max-width: 991px)", query: "(min-width: 768px)"), m: (columns: 6, exclusive_query: "(min-width: 992px) and (max-width: 1199px)", query: "(min-width: 992px)"), l: (columns: 8, exclusive_query: "(min-width: 1200px)", query: "(min-width: 1200px)"), xl: (columns: 8, exclusive_query: "(min-width: 1681px)", query: "(min-width: 1681px)"));
$modulor_breakpoints: (xs: (columns: 4, query: "all", exclusive_query: "all and (max-width: 1023px)"), m: (columns: 6, query: "all and (min-width: 1024px)", exclusive_query: "(min-width: 1024px) and (max-width: 1679px)"), l: (columns: 8, query: "all and (min-width: 1680px)", exclusive_query: "(min-width: 1680px) and (max-width: 1919px)"), xl: (columns: 10, query: "all and (min-width: 1920px)", exclusive_query: "all and (min-width: 1920px)"));
$paths: (about: O-Nas, bistro: Bistro, children_and_families: Prostor/Prostor-Pro-Deti-A-Rodiny, cinema: Kino, clubs: Prostor/Prostor-Pro-Druziny-Stacionare-Nebo-Kluby, community: Prostor, contact: Pro-Navstevniky, education_elementary_schools: Prostor/Pro-Skoly/Zakladni, education_high_schools: Prostor/Pro-Skoly/Stredni, education_kindergartens: Prostor/Pro-Skoly/Materske, education_teachers: Prostor/Pro-Skoly/Pedagog, education: Prostor/Pro-Skoly, email_signature_generator: Email, exhibitions_archive: Vystavy/Archiv, exhibitions: Vystavy, garden: Zahrada, identity: Identita, instagram_generator: Instagram, interventions_archive: Intervence/Archiv, interventions: Intervence, kavarna: Kavarna, library: Knihovna, materialova_knihovna: Materialova-Knihovna, news_archive: Aktuality/Archiv, news: Aktuality, norma_2019: NORMA/2019, norma_2020: NORMA/2020, norma: NORMA, octopus_press_coming_soon: Octopus-Press/Coming-Soon, octopus_press: Octopus-Press, plato_bauhaus: O-Nas/Plato-Bauhaus, press: Press, program_archiv: Program/Archiv, program: Program, projects_archive: Projekty/Archiv, projects: Projekty, residence: Rezidence, root: Index, screens: Screens, rostliny: Rostliny, search: Search, senior_clubs: Prostor/Prostor-Pro-Druziny-Stacionare-Nebo-Kluby/Kluby-Senioru, shared: Sdilene, shop: Prodejna, stage: Stage, stationary: Prostor/Prostor-Pro-Druziny-Stacionare-Nebo-Kluby/Stacionare, students_and_artists: Prostor/Prostor-Pro-Studenty-A-Umelce, teenagers: Prostor/Prostor-Pro-Teenagery, your_events: Prostor/Prostor-Pro-Komunitu, zahrada_pritomnosti: Zahrada-Pritomnosti);
$modulor_paths: (login: Login, logout: Logout, admin: Admin, profile: Admin/Profile, master_pages: Admin/Master-Pages, template_pages: Admin/Template-Pages, master_page_root: Admin/Master-Pages/Root, system: System, access_denied: System/Access-Denied, activity_list: System/Activity-List, attachment_list: System/Attachment-List, error_404: System/404, new_page: System/New-Page, password_reset: System/Password-Reset, registration: System/Registration, permission_list: System/Permission-List);
.octopus_press_article_list_module__content {
  @include plato-text;
  @include font-size(default);

  position: relative;

  @media print {
    // display: block;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.octopus_press_article_list_module__section {
  position: relative;
}

.octopus_press_article_list_module__content[data-filter-option="related"] {
  background-color: color(gray-light);

  &[class*='module__content'] {
    @include breakpoints {
      margin-left: -$web-module-padding * 0.5;
      margin-right: -$web-module-padding * 0.5;

      padding-left: $web-module-padding * 1.5;
      padding-right: $web-module-padding * 1.5;
    }

    @include breakpoints(xs s) {
      margin-left: 0;
      margin-right: 0;

      padding-left: $web-module-padding;
      padding-right: $web-module-padding;
    }
  }
}

.octopus_press_article_list_module__scenes {
  margin: 0;
  padding: 0;
  list-style: none;
}

.octopus_press_article_list_module__scene:not(:last-child) {
  margin-bottom: 3em;
}

.octopus_press_article_list_module__thumbnails {
  width: 100%;

  margin: 0;
  padding: 0;
  list-style: none;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;

  @include breakpoints {
    margin-left: -$web-module-padding;
    margin-right: -$web-module-padding;
  }
}

.octopus_press_article_list_module__content[data-view-option="default"] {
  .octopus_press_article_list_module__thumbnails {}
}

.octopus_press_article_list_module__content[data-view-option="nav"] {
  .octopus_press_article_list_module__thumbnails {
    --scale-factor: 2;

    position: absolute;
    top: 0;
    height: 100%;
    align-content: center;
    justify-items: center;

    @include breakpoints {
      width: calc(100% - #{$web-module-padding} * 2 - #{$web-module-padding});
    }

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: "prev next";

    display: none;
  }
}

.octopus_press_article_list_module__link_to__octopus_press {
  display: block;

  --aspect-ratio: 1.1397616468;

  height: 0;
  padding-bottom: calc(100% * 1 / var(--aspect-ratio));
  position: relative;

  display: block;
}

.octopus_press_article_list_module__octopus_press_logo {
  position: absolute;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
}

.octopus_press_article_list_module__scene__heading {
  @include plato-basic;
  @include font-size(xxlarge);
  text-align: center;

  margin: 0;
  padding: 0;
}

.octopus_press_article_list_module__scene__description_wrapper {
  @include plato-text;
  @include font-size(default);

  margin-bottom: 1em;

  display: grid;

  @include breakpoints {
    grid-template-columns: repeat($columns, 1fr);
    grid-column-gap: $web-module-padding * 2;
  }
}

.octopus_press_article_list_module__exhibition {
  @include plato-text;
  @include font-size(default);

  display: grid;
  grid-row-gap: 1em;

  @include breakpoints {
    grid-template-columns: repeat($columns, 1fr);
    grid-column-gap: $web-module-padding * 2;
  }
}

.octopus_press_article_list_module__exhibition__title,
.octopus_press_article_list_module__exhibition__description {
  grid-column: 3/span 4;

  @include breakpoints(xs) {
    grid-column: 1/span 4;
  }

  @include breakpoints(m s) {
    grid-column: 2/span 4;
  }

  @include breakpoints(l) {
    grid-column: 2/span 6;
  }

  @include breakpoints(xl) {
    grid-column: 3/span 4;
  }
}

.octopus_press_article_list_module__project {
  @include plato-text;
  @include font-size(default);

  margin-bottom: 1em;

  display: grid;
  grid-row-gap: 1em;

  @include breakpoints {
    grid-template-columns: repeat($columns, 1fr);
    grid-column-gap: $web-module-padding * 2;
  }
}

.octopus_press_article_list_module__scene__description,
.octopus_press_article_list_module__scene__details {
  grid-area: description;

  grid-column: 3/span 4;

  @include breakpoints(xs) {
    grid-column: 1/span 4;
  }

  @include breakpoints(m s) {
    grid-column: 2/span 4;
  }

  @include breakpoints(l) {
    grid-column: 2/span 6;
  }

  @include breakpoints(xl) {
    grid-column: 3/span 4;
  }
}

.octopus_press_article_list_module__project__title,
.octopus_press_article_list_module__project__description {
  grid-column: 3/span 4;

  @include breakpoints(xs) {
    grid-column: 1/span 4;
  }

  @include breakpoints(m s) {
    grid-column: 2/span 4;
  }

  @include breakpoints(l) {
    grid-column: 2/span 6;
  }

  @include breakpoints(xl) {
    grid-column: 3/span 4;
  }
}

.octopus_press_article_list_module__scene__summary {
  list-style: none
}

.octopus_press_article_list_module__scene__summary::-webkit-details-marker {
  display: none;
}

.octopus_press_article_list_module__scene__details:not([open]) {
  .octopus_press_article_list_module__scene__summary {
    &:after {
      content: attr(data-more-str);
      cursor: pointer;
      display: inline-block;
    }
  }
}

.octopus_press_article_list_module__scene__details[open] {
  .octopus_press_article_list_module__scene__summary {
    margin-bottom: 1em;
  }
}


.octopus_press_article_list_module__content[data-view-option="default"] {

  &[data-filter-option="exhibition"],
  &[data-filter-option="project"] {
    border-top: .2rem solid;
  }
}

.octopus_press_article_list_module__content:first-child {
  border-top: none !important;
}

.octopus_press_article_list_module__link_to__octopus_press_logo {
  @include font-size(default);

  color: inherit;
  text-decoration: none;

  position: relative;

  --aspect-ratio: 3.9739952719;

  height: 0;
  padding-bottom: calc(100% * 1 / var(--aspect-ratio));
  position: relative;

  display: block;
}

.octopus_press_article_list_module__octopus_press_logo {
  position: absolute;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
}

.octopus_press_article_list_module__octopus_press_header {
  display: block;

  grid-column: 3/span 4;

  @include breakpoints(xs) {
    grid-column: 1/-1;
  }

  @include breakpoints(m s) {
    grid-column: 2/span 4;
  }

  @include breakpoints(l) {
    grid-column: 3/span 4;
  }

  @include breakpoints(xl) {
    grid-column: 3/span 4;
  }
}


.octopus_press_article_list_module__octopus_press_octopus {
  break-inside: avoid;
  display: grid;

  @include breakpoints {
    grid-template-columns: repeat($columns, 1fr);
    grid-column-gap: $web-module-padding * 2;
  }
}

.octopus_press_article_list_module__link_to__octopus_press {
  display: block;

  --aspect-ratio: 1.1397616468;

  height: 0;
  padding-bottom: calc(100% * 1 / var(--aspect-ratio));
  position: relative;

  display: block;

  grid-column: 3/span 4;

  @include breakpoints(xs) {
    grid-column: 2/span 2;
  }

  @include breakpoints(m s) {
    grid-column: 2/span 4;
  }

  @include breakpoints(l) {
    grid-column: 3/span 4;
  }

  @include breakpoints(xl) {
    grid-column: 4/span 2;
  }
}
