body[data-page-type="octopus_press_article_page"][data-article-type="video"] {
  background-color: black; }

body[data-page-type="octopus_press_article_page"][data-article-type="video"]:not(.is_edit_mode) [data-permalink="Octopus-Press"] {
  color: white; }

body[data-page-type="octopus_press_article_page"][data-article-type="image"] {
  background-color: #afafaf; }

body[data-page-type="octopus_press_article_page"][data-article-type="audio"] {
  background-color: #afafaf; }

[data-permalink="Octopus-Press-Article"] {
  position: relative; }
