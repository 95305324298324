.images_module__attachment_images {
  outline: 0;
  width: 100%;
  overflow: hidden; }
  .images_module__attachment_images .images_module__attachment_image {
    all: unset;
    display: block;
    width: 100%; }
  .images_module__attachment_images .images_module__attachment_image__caption {
    all: unset;
    display: block;
    font-family: 'Plato Text', sans-serif;
    font-weight: normal;
    letter-spacing: 0.025em;
    margin-top: 0.5em; }
    @media all and (max-width: 767px) {
      .images_module__attachment_images .images_module__attachment_image__caption {
        font-size: 1.26rem;
        line-height: 1.8rem; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .images_module__attachment_images .images_module__attachment_image__caption {
        font-size: 1.12rem;
        line-height: 1.6rem; } }
    @media (min-width: 992px) and (max-width: 1199px) {
      .images_module__attachment_images .images_module__attachment_image__caption {
        font-size: 1.4rem;
        line-height: 2rem; } }
    @media (min-width: 1200px) {
      .images_module__attachment_images .images_module__attachment_image__caption {
        font-size: 1.4rem;
        line-height: 2rem; } }
    @media (min-width: 1681px) {
      .images_module__attachment_images .images_module__attachment_image__caption {
        font-size: 1.4rem;
        line-height: 2rem; } }
  .images_module__attachment_images .modulor_attachment__responsive {
    all: unset;
    padding-bottom: initial !important; }
    .images_module__attachment_images .modulor_attachment__responsive img {
      all: unset;
      display: block;
      width: 100%;
      height: 100vh;
      object-fit: cover;
      overflow: hidden; }

[data-identity="1.5"] .images_module__attachment_images .images_module__attachment_image__caption {
  font-family: 'Plato Regular', sans-serif;
  font-weight: normal; }
