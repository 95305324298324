@import 'partials';

$breakpoints: (xs: (columns: 4, exclusive_query: "all and (max-width: 767px)", query: "all"), s: (columns: 6, exclusive_query: "(min-width: 768px) and (max-width: 991px)", query: "(min-width: 768px)"), m: (columns: 6, exclusive_query: "(min-width: 992px) and (max-width: 1199px)", query: "(min-width: 992px)"), l: (columns: 8, exclusive_query: "(min-width: 1200px)", query: "(min-width: 1200px)"), xl: (columns: 8, exclusive_query: "(min-width: 1681px)", query: "(min-width: 1681px)"));
$modulor_breakpoints: (xs: (columns: 4, query: "all", exclusive_query: "all and (max-width: 1023px)"), m: (columns: 6, query: "all and (min-width: 1024px)", exclusive_query: "(min-width: 1024px) and (max-width: 1679px)"), l: (columns: 8, query: "all and (min-width: 1680px)", exclusive_query: "(min-width: 1680px) and (max-width: 1919px)"), xl: (columns: 10, query: "all and (min-width: 1920px)", exclusive_query: "all and (min-width: 1920px)"));
$paths: (about: O-Nas, bistro: Bistro, children_and_families: Prostor/Prostor-Pro-Deti-A-Rodiny, cinema: Kino, clubs: Prostor/Prostor-Pro-Druziny-Stacionare-Nebo-Kluby, community: Prostor, contact: Pro-Navstevniky, education_elementary_schools: Prostor/Pro-Skoly/Zakladni, education_high_schools: Prostor/Pro-Skoly/Stredni, education_kindergartens: Prostor/Pro-Skoly/Materske, education_teachers: Prostor/Pro-Skoly/Pedagog, education: Prostor/Pro-Skoly, email_signature_generator: Email, exhibitions_archive: Vystavy/Archiv, exhibitions: Vystavy, garden: Zahrada, identity: Identita, instagram_generator: Instagram, interventions_archive: Intervence/Archiv, interventions: Intervence, kavarna: Kavarna, library: Knihovna, materialova_knihovna: Materialova-Knihovna, news_archive: Aktuality/Archiv, news: Aktuality, norma_2019: NORMA/2019, norma_2020: NORMA/2020, norma: NORMA, octopus_press_coming_soon: Octopus-Press/Coming-Soon, octopus_press: Octopus-Press, plato_bauhaus: O-Nas/Plato-Bauhaus, press: Press, program_archiv: Program/Archiv, program: Program, projects_archive: Projekty/Archiv, projects: Projekty, residence: Rezidence, root: Index, screens: Screens, rostliny: Rostliny, search: Search, senior_clubs: Prostor/Prostor-Pro-Druziny-Stacionare-Nebo-Kluby/Kluby-Senioru, shared: Sdilene, shop: Prodejna, stage: Stage, stationary: Prostor/Prostor-Pro-Druziny-Stacionare-Nebo-Kluby/Stacionare, students_and_artists: Prostor/Prostor-Pro-Studenty-A-Umelce, teenagers: Prostor/Prostor-Pro-Teenagery, your_events: Prostor/Prostor-Pro-Komunitu, zahrada_pritomnosti: Zahrada-Pritomnosti);
$modulor_paths: (login: Login, logout: Logout, admin: Admin, profile: Admin/Profile, master_pages: Admin/Master-Pages, template_pages: Admin/Template-Pages, master_page_root: Admin/Master-Pages/Root, system: System, access_denied: System/Access-Denied, activity_list: System/Activity-List, attachment_list: System/Attachment-List, error_404: System/404, new_page: System/New-Page, password_reset: System/Password-Reset, registration: System/Registration, permission_list: System/Permission-List);
.site_header_module__heading {
  margin: 0;
  padding: 0;

  .plato_logo {
    --animation-transition-step: .2s;
    --animation-pause-length: 10s;
  }

  .plato_logo .letter {
    animation: change-type calc(2 * var(--animation-pause-length)) infinite;
    animation-timing-function: step-start;
  }

  .plato_logo g[data-font="plato-medium"] .letter {
    opacity: 1;
  }

  .plato_logo g[data-font="plato-medium"] .letter:first-child {
    animation-delay: calc(0 * var(--animation-transition-step) + var(--animation-pause-length));
  }

  .plato_logo g[data-font="plato-medium"] .letter:nth-child(2) {
    animation-delay: calc(1 * var(--animation-transition-step) + var(--animation-pause-length));
  }

  .plato_logo g[data-font="plato-medium"] .letter:nth-child(3) {
    animation-delay: calc(2 * var(--animation-transition-step) + var(--animation-pause-length));
  }

  .plato_logo g[data-font="plato-medium"] .letter:nth-child(4) {
    animation-delay: calc(3 * var(--animation-transition-step) + var(--animation-pause-length));
  }

  .plato_logo g[data-font="plato-medium"] .letter:nth-child(5) {
    animation-delay: calc(4 * var(--animation-transition-step) + var(--animation-pause-length));
  }

  .plato_logo g[data-font="plato-regular"] .letter {
    opacity: 0;
  }

  .plato_logo g[data-font="plato-regular"] .letter:first-child {
    animation-delay: calc(0 * var(--animation-transition-step));
  }

  .plato_logo g[data-font="plato-regular"] .letter:nth-child(2) {
    animation-delay: calc(1 * var(--animation-transition-step));
  }

  .plato_logo g[data-font="plato-regular"] .letter:nth-child(3) {
    animation-delay: calc(2 * var(--animation-transition-step));
  }

  .plato_logo g[data-font="plato-regular"] .letter:nth-child(4) {
    animation-delay: calc(3 * var(--animation-transition-step));
  }

  .plato_logo g[data-font="plato-regular"] .letter:nth-child(5) {
    animation-delay: calc(4 * var(--animation-transition-step));
  }

  @keyframes change-type {
    0%, 50% { opacity: 0; }
    51%, 100% { opacity: 1; }
  }
}
