@import 'partials';

$breakpoints: (xs: (columns: 4, exclusive_query: "all and (max-width: 767px)", query: "all"), s: (columns: 6, exclusive_query: "(min-width: 768px) and (max-width: 991px)", query: "(min-width: 768px)"), m: (columns: 6, exclusive_query: "(min-width: 992px) and (max-width: 1199px)", query: "(min-width: 992px)"), l: (columns: 8, exclusive_query: "(min-width: 1200px)", query: "(min-width: 1200px)"), xl: (columns: 8, exclusive_query: "(min-width: 1681px)", query: "(min-width: 1681px)"));
$modulor_breakpoints: (xs: (columns: 4, query: "all", exclusive_query: "all and (max-width: 1023px)"), m: (columns: 6, query: "all and (min-width: 1024px)", exclusive_query: "(min-width: 1024px) and (max-width: 1679px)"), l: (columns: 8, query: "all and (min-width: 1680px)", exclusive_query: "(min-width: 1680px) and (max-width: 1919px)"), xl: (columns: 10, query: "all and (min-width: 1920px)", exclusive_query: "all and (min-width: 1920px)"));
$paths: (about: O-Nas, bistro: Bistro, children_and_families: Prostor/Prostor-Pro-Deti-A-Rodiny, cinema: Kino, clubs: Prostor/Prostor-Pro-Druziny-Stacionare-Nebo-Kluby, community: Prostor, contact: Pro-Navstevniky, education_elementary_schools: Prostor/Pro-Skoly/Zakladni, education_high_schools: Prostor/Pro-Skoly/Stredni, education_kindergartens: Prostor/Pro-Skoly/Materske, education_teachers: Prostor/Pro-Skoly/Pedagog, education: Prostor/Pro-Skoly, email_signature_generator: Email, exhibitions_archive: Vystavy/Archiv, exhibitions: Vystavy, garden: Zahrada, identity: Identita, instagram_generator: Instagram, interventions_archive: Intervence/Archiv, interventions: Intervence, kavarna: Kavarna, library: Knihovna, materialova_knihovna: Materialova-Knihovna, news_archive: Aktuality/Archiv, news: Aktuality, norma_2019: NORMA/2019, norma_2020: NORMA/2020, norma: NORMA, octopus_press_coming_soon: Octopus-Press/Coming-Soon, octopus_press: Octopus-Press, plato_bauhaus: O-Nas/Plato-Bauhaus, press: Press, program_archiv: Program/Archiv, program: Program, projects_archive: Projekty/Archiv, projects: Projekty, residence: Rezidence, root: Index, screens: Screens, rostliny: Rostliny, search: Search, senior_clubs: Prostor/Prostor-Pro-Druziny-Stacionare-Nebo-Kluby/Kluby-Senioru, shared: Sdilene, shop: Prodejna, stage: Stage, stationary: Prostor/Prostor-Pro-Druziny-Stacionare-Nebo-Kluby/Stacionare, students_and_artists: Prostor/Prostor-Pro-Studenty-A-Umelce, teenagers: Prostor/Prostor-Pro-Teenagery, your_events: Prostor/Prostor-Pro-Komunitu, zahrada_pritomnosti: Zahrada-Pritomnosti);
$modulor_paths: (login: Login, logout: Logout, admin: Admin, profile: Admin/Profile, master_pages: Admin/Master-Pages, template_pages: Admin/Template-Pages, master_page_root: Admin/Master-Pages/Root, system: System, access_denied: System/Access-Denied, activity_list: System/Activity-List, attachment_list: System/Attachment-List, error_404: System/404, new_page: System/New-Page, password_reset: System/Password-Reset, registration: System/Registration, permission_list: System/Permission-List);
.homepage_images_module__content {
  @include plato-regular;
  @include font-size(default);



  &[class*='module__content'] {
    padding-left: 0;
    padding-right: 0;

    @include breakpoints {
      margin-left: - $web-module-padding * 0.5;
      margin-right: - $web-module-padding * 0.5;
    }

    @include breakpoints(xs s) {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.homepage_images_module__layout {
  display: grid;

  @include breakpoints {
    grid-template-columns: #{$web-module-padding * 1.5} auto #{$web-module-padding * 2};
  }

  @include breakpoints(xs s) {
    grid-template-columns: #{$web-module-padding} auto #{$web-module-padding * 2};
  }
}

.homepage_images_module__column {
  grid-column-start: 3;

  background-color: color(pink);

  display: flex;
  width: 100%;
  height: 100%;
}

.homepage_images_module__items {
  outline: 0;
  width: 100%;
  overflow: hidden;

  grid-column-start: 2;
}

.homepage_images_module__item {
  width: 100%;

  position: relative;

  overflow: hidden;
}

.homepage_images_module__item__title {
  @include plato-regular;
  @include font-size(xxlarge);

  font-style: italic;

  margin: 0;
  padding: 0;
  pointer-events: none;

  @include breakpoints {
    padding: $web-module-padding;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  &[data-color="pink"] {
    color: color(pink)
  }

  &[data-color="green"] {
    color: color(green)
  }

  &[data-color="yellow"] {
    color: color(yellow)
  }
}

.homepage_images_module__attachment_images {
  @include reset-list;
}

.homepage_images_module__attachment_image {}

.homepage_images_module__link_to__attachment_image {
  color: inherit;
  text-decoration: none;

  display: block;
}

.homepage_images_module__attachment_image__figure {
  all: unset;

  display: block;

  width: 100%;

  margin: 0;
  padding: 0;

  .modulor_attachment__responsive {
    all: unset;
    padding-bottom: initial !important;

    img {
      all: unset;

      display: block;

      width: 100%;
      height: 100vh;
      object-fit: cover;
    }
  }
}

.homepage_images_module__related_pages {
  @include reset-list;

  display: grid;
  grid-template-columns: repeat(2, 1fr);

  margin: 0;
  padding: 0;

  position: absolute;
  width: 100%;
  bottom: 0;
  z-index: 1;
}

.homepage_images_module__link_to__related_page {
  color: inherit;
  text-decoration: none;
}

.homepage_images_module__related_page {
  color: inherit;
  text-decoration: none;

  display: block;
  grid-column: 2 / -1;

  background-color: color(yellow);

  padding-top: .5em;
  padding-bottom: .5em;

  @include breakpoints {
    padding-left: #{$web-module-padding * 0.5};
    padding-right: #{$web-module-padding * 0.5};
  }

  &:hover {
    background-color: color(green);
  }
}

.homepage_images_module__related_page__type {
  @include plato-medium;

  margin: 0;
  padding: 0;

  span {
    text-transform: lowercase;

    &:after {
      content: ":";
    }
  }
}

.homepage_images_module__related_page__title {
  font-style: italic;

  margin: 0;
  padding: 0;
}
