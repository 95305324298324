@mixin prevent-line-break($position: after) {
  white-space: nowrap;

  &:#{$position} {
    content: "\00a0";
    display: inline;
    font-size: 0;
    line-height: 0;
    white-space: nowrap;
  }
}
