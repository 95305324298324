.norma_header_module__date_range {
  font-family: 'Plato Basic', sans-serif;
  font-weight: normal;
  display: flex;
  justify-content: center;
  color: #00ffe4;
  white-space: nowrap;
  letter-spacing: -0.01em;
  font-size: 11.2vw;
  line-height: 1; }
  @media (min-width: 768px) and (max-width: 991px) {
    .norma_header_module__date_range {
      font-size: 10vw; } }
  @media all and (max-width: 767px) {
    .norma_header_module__date_range {
      font-size: 14.1vw; } }
  [data-edition="2020"] .norma_header_module__date_range {
    font-size: 12.6vw; }
    @media (min-width: 768px) and (max-width: 991px) {
      [data-edition="2020"] .norma_header_module__date_range {
        font-size: 11.4vw; } }
    @media all and (max-width: 767px) {
      [data-edition="2020"] .norma_header_module__date_range {
        font-size: 16.2vw; } }
  .norma_header_module__date_range:before {
    font-family: 'Plato Outline', sans-serif;
    font-weight: normal;
    color: black;
    content: attr(data-content);
    position: absolute;
    user-select: none;
    pointer-events: none;
    letter-spacing: 0; }
