.expandable_section_module__button {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  background-color: transparent;
  border-radius: 0;
  border: 0;
  cursor: pointer;
  margin: 0;
  padding: 0;
  font-family: 'Plato Outline', sans-serif;
  font-weight: normal;
  letter-spacing: -0.02em;
  text-align: left;
  display: inline-block;
  user-select: none;
  outline: none;
  padding-left: .65em;
  text-indent: -.65em; }
  @media all and (max-width: 767px) {
    .expandable_section_module__button {
      font-size: 3.84rem;
      line-height: 4.16rem; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .expandable_section_module__button {
      font-size: 5.76rem;
      line-height: 6.24rem; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .expandable_section_module__button {
      font-size: 7.68rem;
      line-height: 8.32rem; } }
  @media (min-width: 1200px) {
    .expandable_section_module__button {
      font-size: 9.6rem;
      line-height: 10.4rem; } }
  @media (min-width: 1681px) {
    .expandable_section_module__button {
      font-size: 9.6rem;
      line-height: 10.4rem; } }
  .expandable_section_module__button:focus {
    color: inherit; }
  .expandable_section_module__button:hover {
    font-family: 'Plato Basic', sans-serif;
    font-weight: normal; }
  .expandable_section_module__button:before {
    content: "\25B6";
    display: inline-block;
    text-indent: 0;
    transition: transform var(--speed, 0.25s); }
  .expandable_section_module__content__is_expanded .expandable_section_module__button {
    font-family: 'Plato Basic', sans-serif;
    font-weight: normal; }
    .expandable_section_module__content__is_expanded .expandable_section_module__button:before {
      transform: rotate(90deg) translateX(0.1em) translateY(0); }

[data-identity="1.5"] .expandable_section_module__button {
  font-family: 'Plato Regular', sans-serif;
  font-weight: normal; }
  [data-identity="1.5"] .expandable_section_module__button:hover {
    font-family: 'Plato Medium', sans-serif;
    font-weight: normal; }

[data-identity="1.5"] .expandable_section_module__content__is_expanded .expandable_section_module__button {
  font-family: 'Plato Medium', sans-serif;
  font-weight: normal; }
