@charset "UTF-8";
.identity_assets_module__preview_attachments__counter {
  font-family: 'Plato Text', sans-serif;
  font-weight: normal;
  letter-spacing: 0.04em;
  position: absolute;
  right: 0; }
  @media all and (max-width: 767px) {
    .identity_assets_module__preview_attachments__counter {
      font-size: 1.8rem;
      line-height: 2.52rem; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .identity_assets_module__preview_attachments__counter {
      font-size: 1.6rem;
      line-height: 2.24rem; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .identity_assets_module__preview_attachments__counter {
      font-size: 1.8rem;
      line-height: 2.52rem; } }
  @media (min-width: 1200px) {
    .identity_assets_module__preview_attachments__counter {
      font-size: 2rem;
      line-height: 2.8rem; } }
  @media (min-width: 1681px) {
    .identity_assets_module__preview_attachments__counter {
      font-size: 2rem;
      line-height: 2.8rem; } }
  @media all and (max-width: 767px) {
    .identity_assets_module__preview_attachments__counter {
      font-size: 1.26rem;
      bottom: calc(-5.04rem); } }
  @media (min-width: 768px) and (max-width: 991px) {
    .identity_assets_module__preview_attachments__counter {
      font-size: 1.12rem;
      bottom: calc(-4.48rem); } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .identity_assets_module__preview_attachments__counter {
      font-size: 1.4rem;
      bottom: calc(-5.04rem); } }
  @media (min-width: 1200px) {
    .identity_assets_module__preview_attachments__counter {
      font-size: 1.4rem;
      bottom: calc(-5.6rem); } }
  @media (min-width: 1681px) {
    .identity_assets_module__preview_attachments__counter {
      font-size: 1.4rem;
      bottom: calc(-5.6rem); } }

.identity_assets_module__preview_attachments__counter__index span,
.identity_assets_module__preview_attachments__counter__total span {
  height: 1px;
  left: -10000px !important;
  overflow: hidden;
  position: absolute;
  top: auto;
  width: 1px; }

.identity_assets_module__preview_attachments__counter__index[data-index="1"]:before, .identity_assets_module__preview_attachments__counter__index[data-total="1"]:before,
.identity_assets_module__preview_attachments__counter__total[data-index="1"]:before,
.identity_assets_module__preview_attachments__counter__total[data-total="1"]:before {
  content: "Ⅰ"; }

.identity_assets_module__preview_attachments__counter__index span,
.identity_assets_module__preview_attachments__counter__total span {
  height: 1px;
  left: -10000px !important;
  overflow: hidden;
  position: absolute;
  top: auto;
  width: 1px; }

.identity_assets_module__preview_attachments__counter__index[data-index="2"]:before, .identity_assets_module__preview_attachments__counter__index[data-total="2"]:before,
.identity_assets_module__preview_attachments__counter__total[data-index="2"]:before,
.identity_assets_module__preview_attachments__counter__total[data-total="2"]:before {
  content: "Ⅱ"; }

.identity_assets_module__preview_attachments__counter__index span,
.identity_assets_module__preview_attachments__counter__total span {
  height: 1px;
  left: -10000px !important;
  overflow: hidden;
  position: absolute;
  top: auto;
  width: 1px; }

.identity_assets_module__preview_attachments__counter__index[data-index="3"]:before, .identity_assets_module__preview_attachments__counter__index[data-total="3"]:before,
.identity_assets_module__preview_attachments__counter__total[data-index="3"]:before,
.identity_assets_module__preview_attachments__counter__total[data-total="3"]:before {
  content: "Ⅲ"; }

.identity_assets_module__preview_attachments__counter__index span,
.identity_assets_module__preview_attachments__counter__total span {
  height: 1px;
  left: -10000px !important;
  overflow: hidden;
  position: absolute;
  top: auto;
  width: 1px; }

.identity_assets_module__preview_attachments__counter__index[data-index="4"]:before, .identity_assets_module__preview_attachments__counter__index[data-total="4"]:before,
.identity_assets_module__preview_attachments__counter__total[data-index="4"]:before,
.identity_assets_module__preview_attachments__counter__total[data-total="4"]:before {
  content: "Ⅳ"; }

.identity_assets_module__preview_attachments__counter__index span,
.identity_assets_module__preview_attachments__counter__total span {
  height: 1px;
  left: -10000px !important;
  overflow: hidden;
  position: absolute;
  top: auto;
  width: 1px; }

.identity_assets_module__preview_attachments__counter__index[data-index="5"]:before, .identity_assets_module__preview_attachments__counter__index[data-total="5"]:before,
.identity_assets_module__preview_attachments__counter__total[data-index="5"]:before,
.identity_assets_module__preview_attachments__counter__total[data-total="5"]:before {
  content: "Ⅴ"; }

.identity_assets_module__preview_attachments__counter__index span,
.identity_assets_module__preview_attachments__counter__total span {
  height: 1px;
  left: -10000px !important;
  overflow: hidden;
  position: absolute;
  top: auto;
  width: 1px; }

.identity_assets_module__preview_attachments__counter__index[data-index="6"]:before, .identity_assets_module__preview_attachments__counter__index[data-total="6"]:before,
.identity_assets_module__preview_attachments__counter__total[data-index="6"]:before,
.identity_assets_module__preview_attachments__counter__total[data-total="6"]:before {
  content: "Ⅵ"; }

.identity_assets_module__preview_attachments__counter__index span,
.identity_assets_module__preview_attachments__counter__total span {
  height: 1px;
  left: -10000px !important;
  overflow: hidden;
  position: absolute;
  top: auto;
  width: 1px; }

.identity_assets_module__preview_attachments__counter__index[data-index="7"]:before, .identity_assets_module__preview_attachments__counter__index[data-total="7"]:before,
.identity_assets_module__preview_attachments__counter__total[data-index="7"]:before,
.identity_assets_module__preview_attachments__counter__total[data-total="7"]:before {
  content: "Ⅶ"; }

.identity_assets_module__preview_attachments__counter__index span,
.identity_assets_module__preview_attachments__counter__total span {
  height: 1px;
  left: -10000px !important;
  overflow: hidden;
  position: absolute;
  top: auto;
  width: 1px; }

.identity_assets_module__preview_attachments__counter__index[data-index="8"]:before, .identity_assets_module__preview_attachments__counter__index[data-total="8"]:before,
.identity_assets_module__preview_attachments__counter__total[data-index="8"]:before,
.identity_assets_module__preview_attachments__counter__total[data-total="8"]:before {
  content: "Ⅷ"; }

.identity_assets_module__preview_attachments__counter__index span,
.identity_assets_module__preview_attachments__counter__total span {
  height: 1px;
  left: -10000px !important;
  overflow: hidden;
  position: absolute;
  top: auto;
  width: 1px; }

.identity_assets_module__preview_attachments__counter__index[data-index="9"]:before, .identity_assets_module__preview_attachments__counter__index[data-total="9"]:before,
.identity_assets_module__preview_attachments__counter__total[data-index="9"]:before,
.identity_assets_module__preview_attachments__counter__total[data-total="9"]:before {
  content: "Ⅸ"; }

.identity_assets_module__preview_attachments__counter__index span,
.identity_assets_module__preview_attachments__counter__total span {
  height: 1px;
  left: -10000px !important;
  overflow: hidden;
  position: absolute;
  top: auto;
  width: 1px; }

.identity_assets_module__preview_attachments__counter__index[data-index="10"]:before, .identity_assets_module__preview_attachments__counter__index[data-total="10"]:before,
.identity_assets_module__preview_attachments__counter__total[data-index="10"]:before,
.identity_assets_module__preview_attachments__counter__total[data-total="10"]:before {
  content: "Ⅹ"; }

.identity_assets_module__preview_attachments__counter__index span,
.identity_assets_module__preview_attachments__counter__total span {
  height: 1px;
  left: -10000px !important;
  overflow: hidden;
  position: absolute;
  top: auto;
  width: 1px; }

.identity_assets_module__preview_attachments__counter__index[data-index="11"]:before, .identity_assets_module__preview_attachments__counter__index[data-total="11"]:before,
.identity_assets_module__preview_attachments__counter__total[data-index="11"]:before,
.identity_assets_module__preview_attachments__counter__total[data-total="11"]:before {
  content: "Ⅺ"; }

.identity_assets_module__preview_attachments__counter__index span,
.identity_assets_module__preview_attachments__counter__total span {
  height: 1px;
  left: -10000px !important;
  overflow: hidden;
  position: absolute;
  top: auto;
  width: 1px; }

.identity_assets_module__preview_attachments__counter__index[data-index="12"]:before, .identity_assets_module__preview_attachments__counter__index[data-total="12"]:before,
.identity_assets_module__preview_attachments__counter__total[data-index="12"]:before,
.identity_assets_module__preview_attachments__counter__total[data-total="12"]:before {
  content: "Ⅻ"; }
