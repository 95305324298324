.soft_tabs_container_module__tab {
  display: inline-block; }
  .soft_tabs_container_module__tab a {
    font-family: 'Plato Basic', sans-serif;
    font-weight: normal;
    letter-spacing: 0.02em;
    background-color: black;
    color: white;
    display: inline-block;
    padding: .2em;
    padding-top: .1em;
    padding-bottom: 0;
    text-decoration: none;
    text-transform: uppercase;
    cursor: default; }
    @media all and (max-width: 767px) {
      .soft_tabs_container_module__tab a {
        font-size: 1.96rem;
        line-height: 2.52rem; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .soft_tabs_container_module__tab a {
        font-size: 1.96rem;
        line-height: 2.52rem; } }
    @media (min-width: 992px) and (max-width: 1199px) {
      .soft_tabs_container_module__tab a {
        font-size: 2.52rem;
        line-height: 3.24rem; } }
    @media (min-width: 1200px) {
      .soft_tabs_container_module__tab a {
        font-size: 2.8rem;
        line-height: 3.6rem; } }
    @media (min-width: 1681px) {
      .soft_tabs_container_module__tab a {
        font-size: 2.8rem;
        line-height: 3.6rem; } }
    [data-past] .soft_tabs_container_module__tab a {
      color: #afafaf; }

.soft_tabs_container_module__tab__inactive a {
  background: none;
  color: black;
  cursor: pointer; }
  [data-past] .soft_tabs_container_module__tab__inactive a {
    color: black; }
  .soft_tabs_container_module__tab__inactive a:hover {
    background-color: black;
    color: white; }
    [data-past] .soft_tabs_container_module__tab__inactive a:hover {
      color: #afafaf; }
