.bricks {
  --animation-transition-step: .02s;
  --animation-duration-in: .2s;
  --animation-duration-out: .1s; }

@keyframes brick-appear {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes brick-disappear {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

[data-anim="in"] .bricks rect {
  --animation-transition-step: .02s;
  animation-name: brick-appear;
  animation-duration: var(--animation-duration-in);
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-timing-function: step-start;
  opacity: 0; }

[data-anim="out"] .bricks rect {
  --animation-transition-step: .01s;
  animation-name: brick-disappear;
  animation-duration: var(--animation-duration-out);
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-timing-function: step-start;
  opacity: 1; }

.bricks rect:nth-child(1) {
  animation-delay: calc(0 * var(--animation-transition-step)); }

.bricks rect:nth-child(2) {
  animation-delay: calc(1 * var(--animation-transition-step)); }

.bricks rect:nth-child(3) {
  animation-delay: calc(2 * var(--animation-transition-step)); }

.bricks rect:nth-child(4) {
  animation-delay: calc(3 * var(--animation-transition-step)); }

.bricks rect:nth-child(5) {
  animation-delay: calc(4 * var(--animation-transition-step)); }

.bricks rect:nth-child(6) {
  animation-delay: calc(5 * var(--animation-transition-step)); }

.bricks rect:nth-child(7) {
  animation-delay: calc(6 * var(--animation-transition-step)); }

.bricks rect:nth-child(8) {
  animation-delay: calc(7 * var(--animation-transition-step)); }

.bricks rect:nth-child(9) {
  animation-delay: calc(8 * var(--animation-transition-step)); }

.bricks rect:nth-child(10) {
  animation-delay: calc(9 * var(--animation-transition-step)); }

.bricks rect:nth-child(11) {
  animation-delay: calc(10 * var(--animation-transition-step)); }

.bricks rect:nth-child(12) {
  animation-delay: calc(11 * var(--animation-transition-step)); }

.bricks rect:nth-child(13) {
  animation-delay: calc(12 * var(--animation-transition-step)); }

.bricks rect:nth-child(14) {
  animation-delay: calc(13 * var(--animation-transition-step)); }

.bricks rect:nth-child(15) {
  animation-delay: calc(14 * var(--animation-transition-step)); }

.bricks rect:nth-child(16) {
  animation-delay: calc(15 * var(--animation-transition-step)); }

.bricks rect:nth-child(17) {
  animation-delay: calc(16 * var(--animation-transition-step)); }

.bricks rect:nth-child(18) {
  animation-delay: calc(17 * var(--animation-transition-step)); }

.bricks rect:nth-child(19) {
  animation-delay: calc(18 * var(--animation-transition-step)); }

.bricks rect:nth-child(20) {
  animation-delay: calc(19 * var(--animation-transition-step)); }

.bricks rect:nth-child(21) {
  animation-delay: calc(21 * var(--animation-transition-step)); }

.bricks rect:nth-child(22) {
  animation-delay: calc(22 * var(--animation-transition-step)); }

.bricks rect:nth-child(23) {
  animation-delay: calc(23 * var(--animation-transition-step)); }

.bricks rect:nth-child(24) {
  animation-delay: calc(24 * var(--animation-transition-step)); }

.bricks rect:nth-child(25) {
  animation-delay: calc(25 * var(--animation-transition-step)); }

.bricks rect:nth-child(26) {
  animation-delay: calc(26 * var(--animation-transition-step)); }

.bricks rect:nth-child(27) {
  animation-delay: calc(27 * var(--animation-transition-step)); }

.bricks rect:nth-child(28) {
  animation-delay: calc(28 * var(--animation-transition-step)); }

.bricks rect:nth-child(29) {
  animation-delay: calc(29 * var(--animation-transition-step)); }

.bricks rect:nth-child(30) {
  animation-delay: calc(30 * var(--animation-transition-step)); }
