.project_header_module__description {
  font-family: 'Plato Text', sans-serif;
  font-weight: normal;
  letter-spacing: 0.04em;
  margin-left: auto;
  margin-right: auto; }
  @media all and (max-width: 767px) {
    .project_header_module__description {
      font-size: 1.8rem;
      line-height: 2.52rem; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .project_header_module__description {
      font-size: 1.6rem;
      line-height: 2.24rem; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .project_header_module__description {
      font-size: 1.8rem;
      line-height: 2.52rem; } }
  @media (min-width: 1200px) {
    .project_header_module__description {
      font-size: 2rem;
      line-height: 2.8rem; } }
  @media (min-width: 1681px) {
    .project_header_module__description {
      font-size: 2rem;
      line-height: 2.8rem; } }
  @media (min-width: 1200px) {
    .project_header_module__description {
      /* l */
      width: 75%; } }
  @media (min-width: 1681px) {
    .project_header_module__description {
      /* xl */
      width: 75%; } }
  @media all and (max-width: 767px) {
    .project_header_module__description {
      padding-left: 1.8rem;
      padding-right: 1.8rem; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .project_header_module__description {
      padding-left: 2.24rem;
      padding-right: 2.24rem; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .project_header_module__description {
      padding-left: 2.52rem;
      padding-right: 2.52rem; } }
  @media (min-width: 1200px) {
    .project_header_module__description {
      padding-left: 2.8rem;
      padding-right: 2.8rem; } }
  @media (min-width: 1681px) {
    .project_header_module__description {
      padding-left: 2.8rem;
      padding-right: 2.8rem; } }

[data-identity="1.5"] .project_header_module__description {
  font-family: 'Plato Regular', sans-serif;
  font-weight: normal; }
