@import 'partials';

$breakpoints: (xs: (columns: 4, exclusive_query: "all and (max-width: 767px)", query: "all"), s: (columns: 6, exclusive_query: "(min-width: 768px) and (max-width: 991px)", query: "(min-width: 768px)"), m: (columns: 6, exclusive_query: "(min-width: 992px) and (max-width: 1199px)", query: "(min-width: 992px)"), l: (columns: 8, exclusive_query: "(min-width: 1200px)", query: "(min-width: 1200px)"), xl: (columns: 8, exclusive_query: "(min-width: 1681px)", query: "(min-width: 1681px)"));
$modulor_breakpoints: (xs: (columns: 4, query: "all", exclusive_query: "all and (max-width: 1023px)"), m: (columns: 6, query: "all and (min-width: 1024px)", exclusive_query: "(min-width: 1024px) and (max-width: 1679px)"), l: (columns: 8, query: "all and (min-width: 1680px)", exclusive_query: "(min-width: 1680px) and (max-width: 1919px)"), xl: (columns: 10, query: "all and (min-width: 1920px)", exclusive_query: "all and (min-width: 1920px)"));
$paths: (about: O-Nas, bistro: Bistro, children_and_families: Prostor/Prostor-Pro-Deti-A-Rodiny, cinema: Kino, clubs: Prostor/Prostor-Pro-Druziny-Stacionare-Nebo-Kluby, community: Prostor, contact: Pro-Navstevniky, education_elementary_schools: Prostor/Pro-Skoly/Zakladni, education_high_schools: Prostor/Pro-Skoly/Stredni, education_kindergartens: Prostor/Pro-Skoly/Materske, education_teachers: Prostor/Pro-Skoly/Pedagog, education: Prostor/Pro-Skoly, email_signature_generator: Email, exhibitions_archive: Vystavy/Archiv, exhibitions: Vystavy, garden: Zahrada, identity: Identita, instagram_generator: Instagram, interventions_archive: Intervence/Archiv, interventions: Intervence, kavarna: Kavarna, library: Knihovna, materialova_knihovna: Materialova-Knihovna, news_archive: Aktuality/Archiv, news: Aktuality, norma_2019: NORMA/2019, norma_2020: NORMA/2020, norma: NORMA, octopus_press_coming_soon: Octopus-Press/Coming-Soon, octopus_press: Octopus-Press, plato_bauhaus: O-Nas/Plato-Bauhaus, press: Press, program_archiv: Program/Archiv, program: Program, projects_archive: Projekty/Archiv, projects: Projekty, residence: Rezidence, root: Index, screens: Screens, rostliny: Rostliny, search: Search, senior_clubs: Prostor/Prostor-Pro-Druziny-Stacionare-Nebo-Kluby/Kluby-Senioru, shared: Sdilene, shop: Prodejna, stage: Stage, stationary: Prostor/Prostor-Pro-Druziny-Stacionare-Nebo-Kluby/Stacionare, students_and_artists: Prostor/Prostor-Pro-Studenty-A-Umelce, teenagers: Prostor/Prostor-Pro-Teenagery, your_events: Prostor/Prostor-Pro-Komunitu, zahrada_pritomnosti: Zahrada-Pritomnosti);
$modulor_paths: (login: Login, logout: Logout, admin: Admin, profile: Admin/Profile, master_pages: Admin/Master-Pages, template_pages: Admin/Template-Pages, master_page_root: Admin/Master-Pages/Root, system: System, access_denied: System/Access-Denied, activity_list: System/Activity-List, attachment_list: System/Attachment-List, error_404: System/404, new_page: System/New-Page, password_reset: System/Password-Reset, registration: System/Registration, permission_list: System/Permission-List);
.graphic_bricks_component__root {
  width: 100%;
  height: 100%;
  --animation-transition-step: .02s;
}

.graphic_bricks_component__root[data-sequence-direction=in] rect {
  visibility: hidden;
}

.graphic_bricks_component__root[data-sequence-direction=out] rect {
  visibility: visible;
}

.graphic_bricks_component__root[data-animation-type=sequence][data-sequence-direction=in] rect,
.graphic_bricks_component__root[data-animation-type=shuffle][data-sequence-direction=in] rect {
  animation-name: graphic_bricks_component__brick_show;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-timing-function: step-start;
}

.graphic_bricks_component__root[data-animation-type=sequence][data-sequence-direction=out] rect,
.graphic_bricks_component__root[data-animation-type=shuffle][data-sequence-direction=out] rect {
  animation-name: graphic_bricks_component__brick_hide;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-timing-function: step-start;
}

.graphic_bricks_component__root[data-animation-type=show][data-sequence-direction=in] rect {
  animation-name: graphic_bricks_component__brick_show;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-timing-function: step-start;
  animation-duration: 0.5s;

  animation-delay: 0s;
}


.graphic_bricks_component__root[data-animation-type=sequence] rect:nth-child(1) {
  animation-delay: calc(0 * var(--animation-transition-step));
}
.graphic_bricks_component__root[data-animation-type=sequence] rect:nth-child(2) {
  animation-delay: calc(1 * var(--animation-transition-step));
}
.graphic_bricks_component__root[data-animation-type=sequence] rect:nth-child(3) {
  animation-delay: calc(2 * var(--animation-transition-step));
}
.graphic_bricks_component__root[data-animation-type=sequence] rect:nth-child(4) {
  animation-delay: calc(3 * var(--animation-transition-step));
}
.graphic_bricks_component__root[data-animation-type=sequence] rect:nth-child(5) {
  animation-delay: calc(4 * var(--animation-transition-step));
}
.graphic_bricks_component__root[data-animation-type=sequence] rect:nth-child(6) {
  animation-delay: calc(5 * var(--animation-transition-step));
}
.graphic_bricks_component__root[data-animation-type=sequence] rect:nth-child(7) {
  animation-delay: calc(6 * var(--animation-transition-step));
}
.graphic_bricks_component__root[data-animation-type=sequence] rect:nth-child(8) {
  animation-delay: calc(7 * var(--animation-transition-step));
}
.graphic_bricks_component__root[data-animation-type=sequence] rect:nth-child(9) {
  animation-delay: calc(8 * var(--animation-transition-step));
}
.graphic_bricks_component__root[data-animation-type=sequence] rect:nth-child(10) {
  animation-delay: calc(9 * var(--animation-transition-step));
}
.graphic_bricks_component__root[data-animation-type=sequence] rect:nth-child(11) {
  animation-delay: calc(10 * var(--animation-transition-step));
}
.graphic_bricks_component__root[data-animation-type=sequence] rect:nth-child(12) {
  animation-delay: calc(11 * var(--animation-transition-step));
}
.graphic_bricks_component__root[data-animation-type=sequence] rect:nth-child(13) {
  animation-delay: calc(12 * var(--animation-transition-step));
}
.graphic_bricks_component__root[data-animation-type=sequence] rect:nth-child(14) {
  animation-delay: calc(13 * var(--animation-transition-step));
}
.graphic_bricks_component__root[data-animation-type=sequence] rect:nth-child(15) {
  animation-delay: calc(14 * var(--animation-transition-step));
}
.graphic_bricks_component__root[data-animation-type=sequence] rect:nth-child(16) {
  animation-delay: calc(15 * var(--animation-transition-step));
}
.graphic_bricks_component__root[data-animation-type=sequence] rect:nth-child(17) {
  animation-delay: calc(16 * var(--animation-transition-step));
}
.graphic_bricks_component__root[data-animation-type=sequence] rect:nth-child(18) {
  animation-delay: calc(17 * var(--animation-transition-step));
}
.graphic_bricks_component__root[data-animation-type=sequence] rect:nth-child(19) {
  animation-delay: calc(18 * var(--animation-transition-step));
}
.graphic_bricks_component__root[data-animation-type=sequence] rect:nth-child(20) {
  animation-delay: calc(19 * var(--animation-transition-step));
}
.graphic_bricks_component__root[data-animation-type=sequence] rect:nth-child(21) {
  animation-delay: calc(21 * var(--animation-transition-step));
}
.graphic_bricks_component__root[data-animation-type=sequence] rect:nth-child(22) {
  animation-delay: calc(22 * var(--animation-transition-step));
}
.graphic_bricks_component__root[data-animation-type=sequence] rect:nth-child(23) {
  animation-delay: calc(23 * var(--animation-transition-step));
}
.graphic_bricks_component__root[data-animation-type=sequence] rect:nth-child(24) {
  animation-delay: calc(24 * var(--animation-transition-step));
}
.graphic_bricks_component__root[data-animation-type=sequence] rect:nth-child(25) {
  animation-delay: calc(25 * var(--animation-transition-step));
}
.graphic_bricks_component__root[data-animation-type=sequence] rect:nth-child(26) {
  animation-delay: calc(26 * var(--animation-transition-step));
}
.graphic_bricks_component__root[data-animation-type=sequence] rect:nth-child(27) {
  animation-delay: calc(27 * var(--animation-transition-step));
}
.graphic_bricks_component__root[data-animation-type=sequence] rect:nth-child(28) {
  animation-delay: calc(28 * var(--animation-transition-step));
}
.graphic_bricks_component__root[data-animation-type=sequence] rect:nth-child(29) {
  animation-delay: calc(29 * var(--animation-transition-step));
}
.graphic_bricks_component__root[data-animation-type=sequence] rect:nth-child(30) {
  animation-delay: calc(30 * var(--animation-transition-step));
}

.graphic_bricks_component__root[data-animation-type=shuffle] rect:nth-child(1) {
  animation-delay: calc(21 * var(--animation-transition-step));
}
.graphic_bricks_component__root[data-animation-type=shuffle] rect:nth-child(2) {
  animation-delay: calc(15 * var(--animation-transition-step));
}
.graphic_bricks_component__root[data-animation-type=shuffle] rect:nth-child(3) {
  animation-delay: calc(19 * var(--animation-transition-step));
}
.graphic_bricks_component__root[data-animation-type=shuffle] rect:nth-child(4) {
  animation-delay: calc(29 * var(--animation-transition-step));
}
.graphic_bricks_component__root[data-animation-type=shuffle] rect:nth-child(5) {
  animation-delay: calc(16 * var(--animation-transition-step));
}
.graphic_bricks_component__root[data-animation-type=shuffle] rect:nth-child(6) {
  animation-delay: calc(1 * var(--animation-transition-step));
}
.graphic_bricks_component__root[data-animation-type=shuffle] rect:nth-child(7) {
  animation-delay: calc(18 * var(--animation-transition-step));
}
.graphic_bricks_component__root[data-animation-type=shuffle] rect:nth-child(8) {
  animation-delay: calc(24 * var(--animation-transition-step));
}
.graphic_bricks_component__root[data-animation-type=shuffle] rect:nth-child(9) {
  animation-delay: calc(14 * var(--animation-transition-step));
}
.graphic_bricks_component__root[data-animation-type=shuffle] rect:nth-child(10) {
  animation-delay: calc(28 * var(--animation-transition-step));
}
.graphic_bricks_component__root[data-animation-type=shuffle] rect:nth-child(11) {
  animation-delay: calc(17 * var(--animation-transition-step));
}
.graphic_bricks_component__root[data-animation-type=shuffle] rect:nth-child(12) {
  animation-delay: calc(5 * var(--animation-transition-step));
}
.graphic_bricks_component__root[data-animation-type=shuffle] rect:nth-child(13) {
  animation-delay: calc(30 * var(--animation-transition-step));
}
.graphic_bricks_component__root[data-animation-type=shuffle] rect:nth-child(14) {
  animation-delay: calc(25 * var(--animation-transition-step));
}
.graphic_bricks_component__root[data-animation-type=shuffle] rect:nth-child(15) {
  animation-delay: calc(13 * var(--animation-transition-step));
}
.graphic_bricks_component__root[data-animation-type=shuffle] rect:nth-child(16) {
  animation-delay: calc(4 * var(--animation-transition-step));
}
.graphic_bricks_component__root[data-animation-type=shuffle] rect:nth-child(17) {
  animation-delay: calc(10 * var(--animation-transition-step));
}
.graphic_bricks_component__root[data-animation-type=shuffle] rect:nth-child(18) {
  animation-delay: calc(22 * var(--animation-transition-step));
}
.graphic_bricks_component__root[data-animation-type=shuffle] rect:nth-child(19) {
  animation-delay: calc(6 * var(--animation-transition-step));
}
.graphic_bricks_component__root[data-animation-type=shuffle] rect:nth-child(21) {
  animation-delay: calc(12 * var(--animation-transition-step));
}
.graphic_bricks_component__root[data-animation-type=shuffle] rect:nth-child(22) {
  animation-delay: calc(26 * var(--animation-transition-step));
}
.graphic_bricks_component__root[data-animation-type=shuffle] rect:nth-child(23) {
  animation-delay: calc(9 * var(--animation-transition-step));
}
.graphic_bricks_component__root[data-animation-type=shuffle] rect:nth-child(24) {
  animation-delay: calc(8 * var(--animation-transition-step));
}
.graphic_bricks_component__root[data-animation-type=shuffle] rect:nth-child(25) {
  animation-delay: calc(27 * var(--animation-transition-step));
}
.graphic_bricks_component__root[data-animation-type=shuffle] rect:nth-child(26) {
  animation-delay: calc(11 * var(--animation-transition-step));
}
.graphic_bricks_component__root[data-animation-type=shuffle] rect:nth-child(27) {
  animation-delay: calc(2 * var(--animation-transition-step));
}
.graphic_bricks_component__root[data-animation-type=shuffle] rect:nth-child(28) {
  animation-delay: calc(7 * var(--animation-transition-step));
}
.graphic_bricks_component__root[data-animation-type=shuffle] rect:nth-child(29) {
  animation-delay: calc(23 * var(--animation-transition-step));
}
.graphic_bricks_component__root[data-animation-type=shuffle] rect:nth-child(30) {
  animation-delay: calc(3 * var(--animation-transition-step));
}
.graphic_bricks_component__root[data-animation-type=shuffle] rect:nth-child(31) {
  animation-delay: calc(0 * var(--animation-transition-step));
}

@keyframes graphic_bricks_component__brick_show {
  0% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

@keyframes graphic_bricks_component__brick_hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}







.graphic_bricks_component__root[data-fill-color=black] {
  fill: color(black);
}
.graphic_bricks_component__root[data-fill-color=white] {
  fill: color(white);
}
.graphic_bricks_component__root[data-fill-color=red] {
  fill: color(red);
}
.graphic_bricks_component__root[data-fill-color=green] {
  fill: color(green);
}
.graphic_bricks_component__root[data-fill-color=yellow] {
  fill: color(yellow);
}
.graphic_bricks_component__root[data-fill-color=gray] {
  fill: color(gray);
}
.graphic_bricks_component__root[data-fill-color=gray-light] {
  fill: color(gray-light);
}
.graphic_bricks_component__root[data-fill-color=pink] {
  fill: color(pink);
}
.graphic_bricks_component__root[data-fill-color=blue-light] {
  fill: color(blue-light);
}
.graphic_bricks_component__root[data-fill-color=orange] {
  fill: color(orange);
}
.graphic_bricks_component__root[data-fill-color=cyan] {
  fill: color(cyan);
}
.graphic_bricks_component__root[data-fill-color=norma_2020] {
  fill: color(norma_2020);
}